.site_info {
	padding: 30px 0;
	text-align: center;
	border-top: 8px solid $body-bg;
	box-shadow: 0 -5px 0 #000;
	background-color: theme(primary, dark);
	color: $white;

	.info_company { margin-bottom: 20px; }

	@include media-breakpoint-up(md) {
		.info_company { margin-bottom: 0; text-align: left; }
		.info_copyright { text-align: right; }
	}

}