.media_object {
	margin: 0 0 1em;
	padding: 30px;
	border: 1px solid #ccc;

	@include media-breakpoint-up(md) {

		.media_content { display: flex; }
			.object_media { padding-right: 1em; }

	}

}

	.media-title {
		margin: -30px -30px 30px;
		padding: 25px 30px;
		background-color: #eaeaea;
	}

	.object_text > *:last-child {
		margin-bottom: 0;
	}


// TEAM MEMBER

.team_member {
	background-color: rgba($white, 0.7);

	.member_media {
		@include media-breakpoint-down(sm) {
			float: right;
			margin: 0 0 15px 15px;
		}
		@include media-breakpoint-up(md) { padding-right: 2em; }
		@include media-breakpoint-up(lg) { padding: 0 4em 0 2em; }
		@include media-breakpoint-up(xl) { padding: 0 6em 0 4em; }
	}
	
}


