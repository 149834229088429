.info_copyright {

	font-size: 0.85rem;
	line-height: 1.3em;

	a {
		text-decoration: none;
		color: $white;
	}

	.copyright-list { @include list_unstyled; }

}