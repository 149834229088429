.review {

	cite {
		display: block;
		margin-top: 1em;
		font-size: 1rem;
		font-weight: 600;
		font-style: normal;
		line-height: 1em;
		img {
			display: block;
			width: 133px;
			margin: 0 0 10px;
		}
	}

	@include media-breakpoint-up(md) {
		cite {
			img {
				display: inline-block;
				width: 120px;
				margin: 0 7px 0 0;
				vertical-align: middle;
			}
		}
	}


	.reviews_main & {

		padding: 1em;
		border: 1px solid #ccc;
		background-color: $white;

		&:not(:last-child) { margin-bottom: 1em; }

	}

}