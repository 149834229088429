.slider_reviews {

	.slick-prev,
	.slick-next {
		z-index: 999999;
		width: 44px;
		border-radius: 6px;
		background-color: transparent;
		background-color: $white;
		background-size: 18px;
		opacity: 1;
		&:hover {
			opacity: 1;
			//background-color: #fbfbfb;
		}
	}

	.slick-prev { background-image: url("/_/images/icons/slide_prev-text.png") }
	.slick-next { background-image: url("/_/images/icons/slide_next-text.png") }	

	.slick-prev:hover { background-image: url("/_/images/icons/slide_prev-review.png") }
	.slick-next:hover { background-image: url("/_/images/icons/slide_next-review.png") }	



	@include media-breakpoint-up(lg) {

/*		.slick-prev,
		.slick-next {
			width: 44px;
			background-size: 100%;
		}
*/
	}

}