body {

	min-width: 360px;

	&:not(.page_front) {
			
		@include media-breakpoint-up(md) {

			height: 100%;
			padding-bottom: 200px;

			.site_info {
				position: absolute;
				bottom: 0;
				width: 100%;
			}

		}
		
	}


}

#directions,
#hours {
	outline: none !important;
	box-shadow: none !important;
}