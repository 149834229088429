

@mixin alert($bg: #ddd, $color: $color-text) {
	border: 1px solid $bg;
	background-color: lighten($bg, 25%);
	color: $color;
	strong, a { color: darken($bg, 15%); }
}

.alert {
	
	@include alert();
	padding: 1em;
	border-radius: 3px;
	
	.button_close {
		float: right;
	}

}

	.alert-primary { @include alert($color-primary); }
	.alert-secondary { @include alert($color-secondary); }
	.alert-highlight { @include alert($color-highlight); }
	.alert-accent { @include alert($color-accent); }
	.alert-success { @include alert($color-success); }
	.alert-danger { @include alert($color-danger); }
	.alert-info { @include alert($color-info); }
	.alert-warning { @include alert($color-warning); }
	.alert-link { @include alert($color-link); }

	.alert-title {
		margin-top: 0;
		margin-right: 34px;
		font-family: $font-family-body;
		font-weight: 900;
		letter-spacing: -0.0325em;
	}

.block.block_alert {

	padding: 0;

	.alert {
		padding-left: 0;
		padding-right: 0;
		border-radius: 0;
		border: none;
	}

	.container {
		position: relative;
		padding: 15px;
	}

	.button_close {
		position: absolute;
		top: 0px; right: 15px;
	}

}