.blog_roll {
}


	.blog_roll_item {
		margin-bottom: 2em;
		padding: 2em;
		border: 1px solid #ddd;
		background-color: $white;

	}

		.roll_item_header {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-bottom: 1em;

			.roll-item-title {
				order: 2;
				flex-grow: 2;
			}

			.item_header_meta {
				order: 1;
				display: inline-block;
				//margin-right: 1em;
			}

		}

			.roll-item-title {
				margin: 0;
				a {
					@include transition(all ease-in-out 300ms);
					display: block;
					padding: 21px 15px;
					font-size: 1.5rem;
					text-decoration: none;
					/*background-image: linear-gradient( to right, theme(primary, base) 50%, $white 50% );
					background-position: top right;
					background-size: 200% 100%;*/
					color: theme(text, base);
					&:hover {
						@include transition(all ease-in-out 300ms);
						/*background-position: top left;*/
						background-color: theme(primary, base);
						color: $white;
					}
				}
				@include media-breakpoint-up(md) {
					a { font-size: 1.8rem; }
				}
			}

		.item_header_meta {
			display: inline-block;
			padding: 12px 15px;
			font-size: 16px;
			font-weight: 600;
			line-height: 14px;
			text-align: center;
			text-transform: uppercase;
			border-right: 5px solid $white;
			background-color: theme(secondary, dark);
			color: $white;
			span { display: block; }
			.meta_date {
				font-size: 24px;
				font-weight: 800;
				line-height: 20px;
				letter-spacing: 0.0275em;
			}
		}

		.attachment-post-thumbnail {
			margin: 0 0 15px;
			padding: 5px;
			border: 1px solid #ccc;
			background-color: $white;
		}

		.roll_item_content {
			> *:last-child { margin-bottom: 0; }
		}


		.posts_nav {
			border-top: 1px solid #aaa;
			padding: 1em 1em 0;
			margin: 0 0 2em;
			a {
				@extend %btn_secondary;
				@extend %btn--sm;
				&:nth-child(2) { float: right; }
			}
		}