.info_company {

	font-size: 0.85rem;
	line-height: 1.3em;

	strong {
		font-size: 1rem;
		font-weight: 600;
		line-height: 1em;
		small { display: block; font-weight: 400; }
	}

	.phone {
		font-size: 1.1rem;
		font-weight: 800;
		line-height: 1.3em;
		color: #222;
		span {
			display: inline-block;
			position: relative;
			vertical-align: top;
			top: -0.05em;
			font-size: 0.85rem;
			font-weight: 600;
			color: #fff;
		}
	}

	.company-list {
		@include list_unstyled;
		> li:first-child,
		> li:nth-child(3) { margin-bottom: 5px; }
	}

}