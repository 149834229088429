.address-street {
	display: block;
}


	.banner-address {

		@include transition(background ease-in-out 300ms);
		display: inline-block;
		text-align: left;
		padding-left: 34px;
		text-decoration: none;
		background: url("/_/images/icons/map-marker.png") left center no-repeat;
		background-size: 24px;
		color: #999;
		&:link, &:active, &:visited, &:focus {
			color: #999;
		}

		&:hover {
			@include transition(background ease-in-out 300ms);
			background-image: url("/_/images/icons/map-marker-hover.png");
			color: #999;
		}

		.address-street {
			color: theme(text, dark);
		}

	}


	.about-address {
		font-size: 0.95rem;
		line-height: 1.3em;
		.address-company {
			display: block;
			margin-bottom: 0.5em;
			font-size: 1.1rem;
			font-weight: 900;
			line-height: 1em;
		}
		.address-phone {
			font-size: 1.5rem;
			font-weight: 900;
			line-height: 1em;
			color: #aaa;
		}
		.address-street { margin-top: 0.5em; }
	}