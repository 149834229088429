.front_jumbo {
	position: relative;
	padding: 0;
	border-top: 5px solid $white;
	background-color: $white;
}

	.jumbo_conversion {

		padding: 15px 0;
		text-align: center;

		.button_conversion {

    		padding: 15px 20px 15px 55px;
			font-size: 1.125rem;
			background-size: 55px auto;
		    transform: scale(0.925);

		    &:hover,
		    &:focus {
	    		padding: 15px 55px 15px 20px;
		        transform: scale(1);
		    }
		    
		}

		@include media-breakpoint-up(md) {
			position: absolute;
			left: 0; right: 0;
			bottom: 113px;
			width: 100%;
			padding: 10px 0;
			font-size: 1.2rem;
			//background-color: rgba(0,0,0,0.425);
		}

		@include media-breakpoint-up(lg) {
			bottom: 118px;
			font-size: 1.375rem;
			.button_conversion { font-size: 1.3rem; }
		}		

		@include media-breakpoint-up(xl) {
			max-width: 1170px;
        	margin: 0 auto;
			.button_conversion { font-size: 1.5rem; }
		}

	}

