.front_reviews {
	padding: 1.5em 0;
	border-bottom: 5px solid #ddd;
	background-color: $white;
	.reviews-title,
	.reviews_more p { background-color: $white; }


	@include media-breakpoint-up(lg) {
		padding: 0;
		background-image: linear-gradient(
			to right,
			$white 75%,
			$gray-100 75%
		);
		.wrap {
			padding-top: 1.5em;
			padding-bottom: 1.5em;
		}
		.reviews_text { @include make-col(9); }
			.reviews_content { @include make-col(12); }
		.reviews_feedback {
			@include make-col(3);
			margin-top: 0;
			background-color: $gray-100;
			p { margin-top: 63px; }
		}
	}

	@include media-breakpoint-up(xl) {
		.reviews_feedback {
			p { margin-top: 73px; }
		}
	}

}

	.reviews_title {
		margin-bottom: 0;
		.reviews-title { padding: 0; }
	}

	.reviews_title,
	.reviews_more,
	.reviews_feedback {
		background-image: none;
	}

	.reviews_content {
		padding-top: 15px;
		padding-bottom: 5px;

		.review-wrap {
			margin: 0 64px;
			text-align: center;
			cite { margin-top: 0.5em; }
		}
	
		@include media-breakpoint-down(sm) {
			.review_stars { margin: 0 auto 10px; }
		}
	}

	.reviews_more {
		p { padding: 0; }
	}


.reviews_feedback {
	margin-top: 2em;
	text-align: center;

	p {
		margin: 0;
		font-size: 1rem;
		line-height: 1em;
	}

	strong {
		display: block;
		margin: 0 0 5px;
		font-weight: 800;
	}

	@include media-breakpoint-only(md) {
		strong {
			display: inline-block;
			margin: 0 15px 0 0;
		}
	}

}