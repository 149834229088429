.banner_menu {

	padding: 40px 0 10px;
	text-align: center;
	background-color: $white;
	background-image: url("/_/images/layout/line-secondary.png");
	background-size: 1px 5px;
	background-repeat: repeat-x;
	background-position: center 55px;

	.container {
		padding: 0;
	}

}


	.banner-menu {
		line-height: 1em;

		.menu {
			display: inline-block;
			padding: 0 3px;
			text-align: center;
			background-color: $white;
		}

			.menu_item {
				display: inline-block;
				padding: 0 3px;
				background-color: $white;
				&.active {
					.menu_link {
						font-size: 16px;
						font-weight: $font-weight-bolder;
						letter-spacing: -0.035em;
						background-color: $white;
						color: theme(highlight, base);
					}
				}
			}

				.menu_link {
					padding: 10px 15px;
					font-size: 14px;
					font-weight: $font-weight-bold;
					line-height: 1em;
					text-transform: uppercase;
					border-radius: 3px;
					background-color: $white;
					color: theme(text, base);
					&:hover {
						background-color: theme(primary, base);
						color: $white;
					}
				}
		
	}

	.banner-book {
		padding: 0 18px;
		background-color: $white;
		@include media-breakpoint-up(lg) {
			.btn {
				padding: 18px 20px 18px 55px;
				font-size: 16px;
				background-size: 28px;
				background-position: 13px 11px;
			}
		}
	}