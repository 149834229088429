$placeholder-color: 		rgba($white, 0.9);
$placeholder-hover: 		rgba(black, 0.3);

.form_quote {

	.form_zip {
		float: left;
		width: 49.5%;
		&.form_destination {
			float: right;
		}
	}

	@include media-breakpoint-only(md) {
		.form_left { float: left; width: 49.5%; }
		.form_right { float: right; width: 49.5%;	}
	}

	@include media-breakpoint-up(xl) {
		.form_left { float: left; width: 49.5%; }
		.form_right { float: right; width: 49.5%;	}
	}

}