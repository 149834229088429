.coverage {

	@extend %card;
	padding: 30px;
	background-color: $gray-100;

	.coverage_title {
		margin: 0 0 0.75rem;
		font-size: 1rem;
		font-weight: 800;
		text-transform: uppercase;
		color: theme(secondary, base);
	}

	p { 
		margin-bottom: 0.5rem;
		line-height: 1.325em;
		&:last-of-type { margin-bottom: 0; }
	}

		.coverage_county {
			font-size: 1rem;
			font-weight: 600;
		}

		.coverage_city {
			font-size: 0.825rem;
		}

}

.block.coverage {
	border: none;
	border-top: 1px solid $gray-300;
	background-color: $white;
	padding: 2em 0 1.75em;
	text-align: center;
	box-shadow: none;

	.row { justify-content: center; }

 	@include media-breakpoint-up(lg) { .wrap { @include make-col(10) } }
	@include media-breakpoint-up(xl) { .wrap { @include make-col(9) } } 

}