.page_title {

	padding: 4em 0 2em;
	border-top: 5px solid $white;
	border-bottom: 5px solid $white;
	background: 	linear-gradient( -45deg, transparent 15%, rgba($white, 0.3) 50%, transparent 85% ),
					linear-gradient( -150deg, rgba(0,0,0,0.2) 20%, transparent 45%, transparent 55%, rgba(0,0,0,0.2) 80% ),
					theme(highlight, base);
	color: $white;

	.page-title {
		margin: 0;
		font-weight: 700;
		text-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
	}

	@include media-breakpoint-up(md) {
		padding-top: 8em;
	}
	
	@include media-breakpoint-up(lg) {
		padding-top: 10em;
	}
	
}