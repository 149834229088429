.front_socials {
	padding: 15px 0 5px;
	text-align: center;
	//background-color: #f5f5f5;
	background-color: #1d2124;

	@include media-breakpoint-up(lg) {
		padding: 15px 0 12px;
		.socials_flex {
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.social_title { margin: 0; padding-right: 10px }
	}

}


.social_title {
	margin-bottom: 10px;
	line-height: 1em;
	text-transform: uppercase;	
}

	.social--title {
		display: inline-block;
		font-size: 1.2rem;
		font-weight: $font-weight-bolder;
		line-height: 1em;
		letter-spacing: -0.0275em;
		color: $white;
	}

.social-list {
	@include list_inline;
	margin-bottom: 0;
	line-height: 1em;
	li { line-height: 1em }
	@include media-breakpoint-up(sm) {
		@include list_inline;
		@include limbo;
	}
}

	.social--item {
		@include transition(transform ease-in-out 300ms);
		display: inline-block;
		padding: 0 10px;
		line-height: 0;
		background-size: 120px 31px;
		background-position: 10px 0;
		background-repeat: no-repeat;
		@include transform(scale(0.95));

		&:not(:last-child) { margin-right: 0.75em }

		&.social--google { background-image: url("/_/images/socials/google_hover.png") }
		&.social--facebook { background-image: url("/_/images/socials/facebook_hover.png") }
		&.social--yelp { background-image: url("/_/images/socials/yelp_hover.png") }

		&:hover {
			@include transition(transform ease-in-out 300ms);
			@include transform(scale(1.05));
			img {
				@include transition(opacity ease-in-out 300ms);
				opacity: 0;
			}
		}

		img {
			@include transition(opacity ease-in-out 300ms);
			width: 120px;
			height: 31px;
			opacity: 1;
		}

	}