.coverage {
  padding: 0.5em;
  border: 1px solid #ddd;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); }

@-ms-viewport {
  width: device-width; }

html {
  position: relative;
  min-height: 100%; }

body {
  min-height: 100%;
  margin: 0;
  background-color: #ececec;
  color: #343a40; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  padding: 0.35em 0.75em 0.625em; }

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

progress {
  vertical-align: baseline; }

textarea {
  overflow: auto; }

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

details {
  display: block; }

summary {
  display: list-item; }

template {
  display: none; }

[hidden] {
  display: none; }

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .wrap {
    padding-right: 0;
    padding-left: 0; }

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  -ms-box-sizing: inherit;
  -o-box-sizing: inherit;
  box-sizing: inherit; }

.wrap {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.flex_container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 1em;
  box-sizing: border-box; }
  .flex_container [class*="col-"] {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding: 0 15px; }
  .flex_container.gutter-fix [class*="col-"] {
    padding-left: 0; }
  @media (min-width: 576px) {
    .flex_container .col-sm-12 {
      width: 100%; }
    .flex_container .col-sm-11 {
      width: 91.66667%; }
    .flex_container .col-sm-10 {
      width: 83.33333%; }
    .flex_container .col-sm-9 {
      width: 75%; }
    .flex_container .col-sm-8 {
      width: 66.66667%; }
    .flex_container .col-sm-7 {
      width: 58.33333%; }
    .flex_container .col-sm-6 {
      width: 50%; }
    .flex_container .col-sm-5 {
      width: 41.66667%; }
    .flex_container .col-sm-4 {
      width: 33.33333%; }
    .flex_container .col-sm-3 {
      width: 25%; }
    .flex_container .col-sm-2 {
      width: 16.66667%; }
    .flex_container .col-sm-1 {
      width: 8.33333%; } }
  @media (min-width: 768px) {
    .flex_container .col-md-break {
      width: 100%; }
    .flex_container .col-md-12 {
      width: 100%; }
    .flex_container .col-md-11 {
      width: 91.66667%; }
    .flex_container .col-md-10 {
      width: 83.33333%; }
    .flex_container .col-md-9 {
      width: 75%; }
    .flex_container .col-md-8 {
      width: 66.66667%; }
    .flex_container .col-md-7 {
      width: 58.33333%; }
    .flex_container .col-md-6 {
      width: 50%; }
    .flex_container .col-md-5 {
      width: 41.66667%; }
    .flex_container .col-md-4 {
      width: 33.33333%; }
    .flex_container .col-md-3 {
      width: 25%; }
    .flex_container .col-md-2 {
      width: 16.66667%; }
    .flex_container .col-md-1 {
      width: 8.33333%; } }
  @media (min-width: 992px) {
    .flex_container .col-lg-break {
      width: 100%; }
    .flex_container .col-lg-12 {
      width: 100%; }
    .flex_container .col-lg-11 {
      width: 91.66667%; }
    .flex_container .col-lg-10 {
      width: 83.33333%; }
    .flex_container .col-lg-9 {
      width: 75%; }
    .flex_container .col-lg-8 {
      width: 66.66667%; }
    .flex_container .col-lg-7 {
      width: 58.33333%; }
    .flex_container .col-lg-6 {
      width: 50%; }
    .flex_container .col-lg-5 {
      width: 41.66667%; }
    .flex_container .col-lg-4 {
      width: 33.33333%; }
    .flex_container .col-lg-3 {
      width: 25%; }
    .flex_container .col-lg-2 {
      width: 16.66667%; }
    .flex_container .col-lg-1 {
      width: 8.33333%; } }
  @media (min-width: 1200px) {
    .flex_container .col-xl-break {
      width: 100%; }
    .flex_container .col-xl-12 {
      width: 100%; }
    .flex_container .col-xl-11 {
      width: 91.66667%; }
    .flex_container .col-xl-10 {
      width: 83.33333%; }
    .flex_container .col-xl-9 {
      width: 75%; }
    .flex_container .col-xl-8 {
      width: 66.66667%; }
    .flex_container .col-xl-7 {
      width: 58.33333%; }
    .flex_container .col-xl-6 {
      width: 50%; }
    .flex_container .col-xl-5 {
      width: 41.66667%; }
    .flex_container .col-xl-4 {
      width: 33.33333%; }
    .flex_container .col-xl-3 {
      width: 25%; }
    .flex_container .col-xl-2 {
      width: 16.66667%; }
    .flex_container .col-xl-1 {
      width: 8.33333%; } }

@media (min-width: 576px) {
  .banner .row {
    align-items: center; }
  .banner .banner_branding {
    order: 1; }
  .banner .banner_contact {
    flex: 0 0 50%;
    max-width: 50%; }
  .banner .contact_long {
    order: 2; }
  .banner .contact_local {
    order: 3; } }

@media (min-width: 768px) {
  .banner .contact_long {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    order: 1; }
  .banner .banner_branding {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    order: 2; }
  .banner .contact_local {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    order: 3; } }

@media (min-width: 992px) {
  .content .main {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .content .sidebar {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .content .sidebar {
    padding-left: 15px; } }

@media (min-width: 992px) {
  .blog_roll .main,
  .block_article .main {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .blog_roll .sidebar,
  .block_article .sidebar {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; } }

@media (min-width: 992px) {
  .page_contact .content_text {
    flex: 0 0 50%;
    max-width: 50%; }
  .page_contact .content_contact {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .page_contact .content_text {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .page_contact .content_contact {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
    padding-left: 0; } }

@media (min-width: 992px) {
  .content.flip .main {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
    order: 2; }
  .content.flip .sidebar {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    order: 1; } }

@media (min-width: 768px) {
  .site_info .row {
    align-items: center; }
  .site_info .wrap {
    flex: 0 0 50%;
    max-width: 50%; } }

body {
  min-width: 360px; }
  @media (min-width: 768px) {
    body:not(.page_front) {
      height: 100%;
      padding-bottom: 200px; }
      body:not(.page_front) .site_info {
        position: absolute;
        bottom: 0;
        width: 100%; } }

#directions,
#hours {
  outline: none !important;
  box-shadow: none !important; }

html {
  font-size: 16px;
  line-height: 1.725em;
  -webkit-text-size-adjust: 100%; }
  @media (min-width: 768px) {
    html {
      font-size: 16.96px; } }

body {
  font-size: 1rem;
  font-family: "Montserrat", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif; }

h4, .h4, h3, .h3, h2, .h2, .block-title, h1, .h1 {
  margin: 1.5em 0 0.75em;
  font-family: "Rokkitt", "Times New Roman", Georgia, Times, serif;
  font-weight: 600;
  line-height: 1em;
  text-transform: uppercase; }
  h4 small, .h4 small, h3 small, .h3 small, h2 small, .h2 small, .block-title small, h1 small, .h1 small {
    display: block;
    font-weight: 400; }

h4, .h4 {
  font-size: 1.15rem; }
  @media (min-width: 768px) {
    h4, .h4 {
      font-size: 1.25rem; } }
  @media (min-width: 992px) {
    h4, .h4 {
      font-size: 1.35rem; } }
  @media (min-width: 1200px) {
    h4, .h4 {
      font-size: 1.45rem; } }

h3, .h3 {
  font-size: 1.1rem;
  font-weight: 900; }
  @media (min-width: 768px) {
    h3, .h3 {
      font-size: 1.1rem; } }
  @media (min-width: 992px) {
    h3, .h3 {
      font-size: 1.3rem; } }
  @media (min-width: 1200px) {
    h3, .h3 {
      font-size: 1.5rem; } }

h2, .h2, .block-title {
  font-size: 1.3rem;
  letter-spacing: 0.0375em; }
  @media (min-width: 768px) {
    h2, .h2, .block-title {
      font-size: 1.3rem; } }
  @media (min-width: 992px) {
    h2, .h2, .block-title {
      font-size: 1.5rem; } }
  @media (min-width: 1200px) {
    h2, .h2, .block-title {
      font-size: 1.8rem; } }

h1, .h1 {
  font-size: 1.8rem; }
  @media (min-width: 768px) {
    h1, .h1 {
      font-size: 2em; } }
  @media (min-width: 992px) {
    h1, .h1 {
      font-size: 2.2rem; } }
  @media (min-width: 1200px) {
    h1, .h1 {
      font-size: 2.4rem; } }

p {
  margin: 0 0 1em; }
  p.lead {
    font-size: 120%;
    line-height: 1.53em; }

ul, ol {
  margin: 0;
  padding: 0; }

.b0 {
  font-weight: 400; }

b, strong, .b1 {
  font-weight: 600; }

.bolder, .stronger, .b2 {
  font-weight: 800; }

address {
  margin-bottom: 1em;
  font-style: normal; }

blockquote {
  margin: 0 0 1.5em;
  padding: 1em;
  font-size: 112.5%;
  line-height: 1.425em;
  border-left: 0.5rem solid; }
  blockquote > * {
    margin-bottom: 0.5em; }
  blockquote > *:last-child {
    margin-bottom: 0; }
  blockquote.bq--text {
    border-color: #343a40; }
  blockquote.bq--primary {
    border-color: #41A72C; }
  blockquote.bq--secondary {
    border-color: #060301; }
  blockquote.bq--highlight {
    border-color: #01A3D9; }
  blockquote.bq--accent {
    border-color: #F1D620; }
  blockquote.bq--success {
    border-color: #28a745; }
  blockquote.bq--danger {
    border-color: #dc3545; }
  blockquote.bq--warning {
    border-color: #d39e00; }
  blockquote.bq--info {
    border-color: #6610f2; }
  blockquote.review {
    padding: 0;
    margin: 0 0 1em;
    font-size: 1rem;
    border: none; }

.bq--title {
  font-weight: 800;
  letter-spacing: -0.0275em;
  text-transform: uppercase; }
  .bq--text .bq--title {
    color: #343a40; }
  .bq--primary .bq--title {
    color: #41A72C; }
  .bq--secondary .bq--title {
    color: #060301; }
  .bq--highlight .bq--title {
    color: #01A3D9; }
  .bq--accent .bq--title {
    color: #F1D620; }
  .bq--success .bq--title {
    color: #28a745; }
  .bq--danger .bq--title {
    color: #dc3545; }
  .bq--warning .bq--title {
    color: #d39e00; }
  .bq--info .bq--title {
    color: #6610f2; }

blockquote.bq-alt {
  margin-right: 5em;
  padding: 1.5em;
  border-radius: 6px;
  border: 4px solid #343a40;
  background-color: #fff; }
  blockquote.bq-alt.bq--primary {
    border-color: #41A72C; }
  blockquote.bq-alt.bq--secondary {
    border-color: #060301; }
  blockquote.bq-alt.bq--highlight {
    border-color: #01A3D9; }
  blockquote.bq-alt.bq--accent {
    border-color: #F1D620; }
  blockquote.bq-alt.bq--success {
    border-color: #28a745; }
  blockquote.bq-alt.bq--danger {
    border-color: #dc3545; }
  blockquote.bq-alt.bq--warning {
    border-color: #ffc107; }
  blockquote.bq-alt.bq--info {
    border-color: #6610f2; }

hr {
  display: block;
  width: 100%;
  height: 1px;
  margin: 2em 0;
  border: none;
  background-color: #333; }
  hr.hr-inset {
    margin: 2em auto 1em;
    border: none;
    background-color: #bbb;
    box-shadow: 0 1px 0 0 #fff; }

a {
  font-style: normal;
  text-decoration: underline;
  color: #17a2b8; }
  a:hover, a:focus {
    color: #0f6674;
    text-decoration: none; }
  a:focus {
    outline: 1px solid #733a13; }

.phone {
  font-family: "Montserrat", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-decoration: none;
  white-space: nowrap;
  cursor: default;
  color: #343a40; }
  .phone:hover {
    text-decoration: none;
    color: #343a40;
    outline: none;
    cursor: default; }

.phone.mask {
  color: #343a40; }
  .phone.mask:hover {
    outline: none;
    color: #343a40; }

.list {
  margin: 0 0 1em;
  padding: 0 0 0 1.2em; }
  .list li:not(:last-child) {
    margin-bottom: 0.25em; }

.list-inline, .list_icon, .list-hours, .comments-list, .comments-list ol, .menu, .list-unstyled {
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
  list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); }

.list-inline li {
  display: inline-block; }

.list_icon li {
  padding-left: 2em;
  font-size: 1.125rem;
  background-repeat: no-repeat;
  background-size: 1.25rem;
  background-position: left center; }

.icon_check-primary li {
  background-image: url("/_/images/icons/cm_primary.svg"); }

.icon_check-secondary li {
  background-image: url("/_/images/icons/cm_secondary.svg"); }

.icon_check-success li {
  background-image: url("/_/images/icons/cm_success.svg"); }

[class*="list-block"] {
  margin: 0 0 1em;
  padding: 0 0 0 1.2em;
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
  list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); }
  [class*="list-block"] li:not(:last-child) {
    margin-bottom: 0.25em; }
  [class*="list-block"] li {
    display: inline-block; }
  [class*="list-block"] li {
    -webkit-transform: skew(-15deg);
    -moz-transform: skew(-15deg);
    -o-transform: skew(-15deg);
    transform: skew(-15deg);
    margin-bottom: 0.5em !important;
    padding: 3px 10px;
    font-weight: 900;
    letter-spacing: 0.025em;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
    background-color: #343a40;
    color: #fff; }
  [class*="list-block"] li:not(:last-child) {
    margin-right: 0.25em; }

.list-block--primary li {
  background-color: #489c37; }

.list-block--secondary li {
  background-color: #060301; }

.list-block--highlight li {
  background-color: #01A3D9; }

.list-block--accent li {
  background-color: #F1D620; }

.list-block--success li {
  background-color: #28a745; }

.list-block--danger li {
  background-color: #dc3545; }

.list-block--warning li {
  background-color: #ffc107; }

.list-block--info li {
  background-color: #6610f2; }

img:not(.bbblinkimg) {
  display: block;
  width: 100%;
  height: auto;
  border-style: none; }

.img-main {
  margin: 0 0 15px;
  max-width: 360px; }

.img-coupon {
  max-width: 640px; }

.img-about {
  width: 150px; }

.img-thumbnail {
  padding: 5px;
  border: 1px solid #ddd;
  background-color: #fff; }

@media (min-width: 768px) {
  .img--right {
    clear: both;
    float: right;
    width: 45%;
    margin: 0 0 15px 20px; } }

@media (min-width: 768px) {
  .img--left {
    clear: both;
    float: left;
    width: 45%;
    margin: 0 20px 15px 0; } }

.img--wide {
  margin: 0 0 15px;
  max-width: none; }

.img--auto {
  max-width: auto; }
  @media (min-width: 576px) {
    .img--auto {
      width: auto;
      height: auto; } }

.img-captioned {
  padding: 8px;
  border: 1px solid #ccc;
  background-color: #fff;
  margin-bottom: 15px; }
  .img-captioned img {
    padding: 0;
    border: none;
    margin-bottom: 0; }
  .img-captioned span {
    display: block;
    max-width: 360px;
    margin: 10px 5px 2px;
    font-size: 0.92rem;
    font-style: italic;
    line-height: 1.325em;
    color: #4b545c; }

.hidden {
  display: none !important; }

@media (max-width: 575px) {
  .hidden--xs {
    display: none !important; } }

@media (min-width: 576px) and (max-width: 767px) {
  .hidden--sm {
    display: none !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .hidden--md {
    display: none !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden--lg {
    display: none !important; } }

@media (min-width: 1200px) {
  .hidden--xl {
    display: none !important; } }

[class*="visible-"] {
  display: none; }

@media (max-width: 575px) {
  .visible--xs {
    display: block !important; } }

@media (min-width: 576px) and (max-width: 767px) {
  .visible--sm {
    display: block !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible--md {
    display: block !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible--lg {
    display: block !important; } }

@media (min-width: 1200px) {
  .visible--xl {
    display: block !important; } }

.db {
  display: block !important; }

.mt0 {
  margin-top: 0 !important; }

.mb0 {
  margin-bottom: 0 !important; }

.mt15 {
  margin-top: 15px !important; }

.mb15 {
  margin-bottom: 15px !important; }

.notrfm {
  transform: none !important; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.txt--up {
  text-transform: uppercase !important; }

.txt--cap {
  text-transform: capitalize !important; }

.txt--lg {
  font-size: 115% !important; }

.txt--md {
  font-size: inherit !important; }

.txt--sm {
  font-size: 85% !important; }

.lh1 {
  line-height: 1.1em !important; }

.lh125 {
  line-height: 1.25em !important; }

.txt--left {
  text-align: left !important; }

.txt--right {
  text-align: right !important; }

.txt--center {
  text-align: center !important; }

.color--text {
  color: #343a40 !important; }

.color--white {
  color: #fff !important; }

.color--primary {
  color: #41A72C !important; }

.color--secondary {
  color: #060301 !important; }

.color--highlight {
  color: #01A3D9 !important; }

.color--link {
  color: #17a2b8 !important; }

.color--review {
  color: #fd7e14 !important; }

.color--success {
  color: #28a745 !important; }

.color--danger {
  color: #dc3545 !important; }

.color--warning {
  color: #ffc107 !important; }

.color--info {
  color: #6610f2 !important; }

.bg--text {
  background-color: #343a40 !important; }

.bg--white {
  background-color: #fff !important; }

.bg--primary {
  background-color: #41A72C !important; }

.bg--secondary {
  background-color: #060301 !important; }

.bg--highlight {
  background-color: #01A3D9 !important; }

.bg--link {
  background-color: #17a2b8 !important; }

.bg--review {
  background-color: #fd7e14 !important; }

.bg--success {
  background-color: #28a745 !important; }

.bg--danger {
  background-color: #dc3545 !important; }

.bg--warning {
  background-color: #ffc107 !important; }

.bg--info {
  background-color: #6610f2 !important; }

.bg--transparent {
  background-color: transparent !important; }

.bg--body {
  background-color: #ececec !important; }

.gdfancybg--text {
  background: #343a40;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #343a40; }

.gdfancybg--white {
  background: #fff;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #fff; }

.gdfancybg--primary {
  background: #41A72C;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #41A72C; }

.gdfancybg--secondary {
  background: #060301;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #060301; }

.gdfancybg--highlight {
  background: #01A3D9;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #01A3D9; }

.gdfancybg--review {
  background: #fd7e14;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #fd7e14; }

.gdfancybg--success {
  background: #28a745;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #28a745; }

.gdfancybg--danger {
  background: #dc3545;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #dc3545; }

.gdfancybg--warning {
  background: #ffc107;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #ffc107; }

.gdfancybg--info {
  background: #6610f2;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #6610f2; }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 15px;
  line-height: 1em;
  background-color: #fff;
  border-spacing: 0;
  border-collapse: collapse;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }
  .table * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
  .table th,
  .table td {
    padding: 0.5em 0.75em;
    vertical-align: top;
    border-top: 1px solid #ccc; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #ccc; }
  .table tbody + tbody {
    border-top: 2px solid #ccc; }

.table-bordered {
  border: 1px solid #ccc; }
  .table-bordered th, .table-bordered td {
    border: 1px solid #ccc; }
  .table-bordered thead th, .table-bordered thead td {
    border-bottom-width: 2px; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f3f3f3; }

.table-hover tbody tr:hover {
  background-color: #e5e5e5;
  cursor: pointer; }

@media (max-width: 767px) {
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive > .table-bordered {
      border: 0; } }

.embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden; }
  .embed-responsive.embed-responsive-16by9 {
    padding-bottom: 56.25%; }
  .embed-responsive.embed-responsive-4by3 {
    padding-bottom: 75%; }
  .embed-responsive .embed-responsive-item, .embed-responsive embed, .embed-responsive iframe, .embed-responsive object, .embed-responsive video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0; }

.address-street {
  display: block; }

.banner-address {
  -webkit-transition: background ease-in-out 300ms;
  -moz-transition: background ease-in-out 300ms;
  -ms-transition: background ease-in-out 300ms;
  -o-transition: background ease-in-out 300ms;
  transition: background ease-in-out 300ms;
  display: inline-block;
  text-align: left;
  padding-left: 34px;
  text-decoration: none;
  background: url("/_/images/icons/map-marker.png") left center no-repeat;
  background-size: 24px;
  color: #999; }
  .banner-address:link, .banner-address:active, .banner-address:visited, .banner-address:focus {
    color: #999; }
  .banner-address:hover {
    -webkit-transition: background ease-in-out 300ms;
    -moz-transition: background ease-in-out 300ms;
    -ms-transition: background ease-in-out 300ms;
    -o-transition: background ease-in-out 300ms;
    transition: background ease-in-out 300ms;
    background-image: url("/_/images/icons/map-marker-hover.png");
    color: #999; }
  .banner-address .address-street {
    color: #1d2124; }

.about-address {
  font-size: 0.95rem;
  line-height: 1.3em; }
  .about-address .address-company {
    display: block;
    margin-bottom: 0.5em;
    font-size: 1.1rem;
    font-weight: 900;
    line-height: 1em; }
  .about-address .address-phone {
    font-size: 1.5rem;
    font-weight: 900;
    line-height: 1em;
    color: #aaa; }
  .about-address .address-street {
    margin-top: 0.5em; }

.alert {
  border: 1px solid #ddd;
  background-color: white;
  color: #343a40;
  padding: 1em;
  border-radius: 3px; }
  .alert strong, .alert a {
    color: #b7b7b7; }
  .alert .button_close {
    float: right; }

.alert-primary {
  border: 1px solid #41A72C;
  background-color: #88db77;
  color: #343a40; }
  .alert-primary strong, .alert-primary a {
    color: #296a1c; }

.alert-secondary {
  border: 1px solid #060301;
  background-color: #733a13;
  color: #343a40; }
  .alert-secondary strong, .alert-secondary a {
    color: black; }

.alert-highlight {
  border: 1px solid #01A3D9;
  background-color: #5bd5fe;
  color: #343a40; }
  .alert-highlight strong, .alert-highlight a {
    color: #016a8d; }

.alert-accent {
  border: 1px solid #F1D620;
  background-color: #f9ec98;
  color: #343a40; }
  .alert-accent strong, .alert-accent a {
    color: #b9a30c; }

.alert-success {
  border: 1px solid #28a745;
  background-color: #71dd8a;
  color: #343a40; }
  .alert-success strong, .alert-success a {
    color: #19692c; }

.alert-danger {
  border: 1px solid #dc3545;
  background-color: #efa2a9;
  color: #343a40; }
  .alert-danger strong, .alert-danger a {
    color: #a71d2a; }

.alert-info {
  border: 1px solid #6610f2;
  background-color: #b389f9;
  color: #343a40; }
  .alert-info strong, .alert-info a {
    color: #4709ac; }

.alert-warning {
  border: 1px solid #ffc107;
  background-color: #ffe187;
  color: #343a40; }
  .alert-warning strong, .alert-warning a {
    color: #ba8b00; }

.alert-link {
  border: 1px solid #17a2b8;
  background-color: #63d9ec;
  color: #343a40; }
  .alert-link strong, .alert-link a {
    color: #0f6674; }

.alert-title {
  margin-top: 0;
  margin-right: 34px;
  font-family: "Montserrat", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 900;
  letter-spacing: -0.0325em; }

.block.block_alert {
  padding: 0; }
  .block.block_alert .alert {
    padding-left: 0;
    padding-right: 0;
    border-radius: 0;
    border: none; }
  .block.block_alert .container {
    position: relative;
    padding: 15px; }
  .block.block_alert .button_close {
    position: absolute;
    top: 0px;
    right: 15px; }

.alert_scheduling {
  /*	background: 	linear-gradient(to right, rgba(#B72A6F, 0.25), rgba(#FF8A00, 0.25) ),
					$white;*/ }
  .alert_scheduling .container {
    padding: 0 15px !important; }
  .alert_scheduling .alert {
    position: relative;
    padding-top: 0;
    padding-bottom: 0;
    background-color: #fff !important; }
    .alert_scheduling .alert a {
      color: #b62a71; }
  @media (min-width: 768px) {
    .alert_scheduling {
      background: linear-gradient(to top, #343a40 68px, transparent 68px), linear-gradient(to right, rgba(183, 42, 111, 0.8), rgba(255, 138, 0, 0.8)), #fff; }
      .alert_scheduling .alert {
        padding-top: 0;
        background-color: transparent !important; }
      .alert_scheduling .container {
        padding-top: 15px !important; }
      .alert_scheduling .button_close {
        top: 15px !important; }
      .alert_scheduling .wrap {
        padding: 30px 30px 0;
        border-radius: 3px;
        background-color: #fff; }
      .alert_scheduling .alert_note {
        margin: 15px 0 0; } }
  @media (min-width: 992px) {
    .alert_scheduling .wrap {
      flex: 0 0 75%;
      max-width: 75%; }
    .alert_scheduling .mindbody-link {
      margin-bottom: 20px; } }
  @media (min-width: 1200px) {
    .alert_scheduling .wrap {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; }
    .alert_scheduling .scheduling-title {
      font-size: 1.35rem; } }

.alert_text {
  margin-right: 35px; }
  @media (min-width: 992px) {
    .alert_text {
      margin: 0 auto;
      padding-right: 35px; } }

.scheduling-title {
  margin-right: 0;
  color: #e55828;
  font-weight: 700;
  text-transform: uppercase; }

.mindbody-link {
  display: block;
  max-width: 240px;
  margin-bottom: 10px;
  font-weight: 600; }
  .mindbody-link small {
    display: block; }
  @media (min-width: 576px) {
    .mindbody-link {
      float: right;
      margin: 0 0 0 10px;
      text-align: right; }
      .mindbody-link small {
        margin-top: -5px; } }

.alert_note {
  padding: 15px;
  font-size: 0.96rem;
  font-weight: 600;
  line-height: 1.175em;
  background-color: #343a40;
  color: #fff; }
  @media (min-width: 576px) and (max-width: 767px) {
    .alert_note p {
      max-width: 540px;
      margin: 0 auto; } }
  @media (min-width: 576px) {
    .alert_note {
      text-align: center; } }

.blog_roll_item {
  margin-bottom: 2em;
  padding: 2em;
  border: 1px solid #ddd;
  background-color: #fff; }

.roll_item_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1em; }
  .roll_item_header .roll-item-title {
    order: 2;
    flex-grow: 2; }
  .roll_item_header .item_header_meta {
    order: 1;
    display: inline-block; }

.roll-item-title {
  margin: 0; }
  .roll-item-title a {
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms;
    display: block;
    padding: 21px 15px;
    font-size: 1.5rem;
    text-decoration: none;
    /*background-image: linear-gradient( to right, theme(primary, base) 50%, $white 50% );
					background-position: top right;
					background-size: 200% 100%;*/
    color: #343a40; }
    .roll-item-title a:hover {
      -webkit-transition: all ease-in-out 300ms;
      -moz-transition: all ease-in-out 300ms;
      -ms-transition: all ease-in-out 300ms;
      -o-transition: all ease-in-out 300ms;
      transition: all ease-in-out 300ms;
      /*background-position: top left;*/
      background-color: #41A72C;
      color: #fff; }
  @media (min-width: 768px) {
    .roll-item-title a {
      font-size: 1.8rem; } }

.item_header_meta {
  display: inline-block;
  padding: 12px 15px;
  font-size: 16px;
  font-weight: 600;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;
  border-right: 5px solid #fff;
  background-color: black;
  color: #fff; }
  .item_header_meta span {
    display: block; }
  .item_header_meta .meta_date {
    font-size: 24px;
    font-weight: 800;
    line-height: 20px;
    letter-spacing: 0.0275em; }

.attachment-post-thumbnail {
  margin: 0 0 15px;
  padding: 5px;
  border: 1px solid #ccc;
  background-color: #fff; }

.roll_item_content > *:last-child {
  margin-bottom: 0; }

.posts_nav {
  border-top: 1px solid #aaa;
  padding: 1em 1em 0;
  margin: 0 0 2em; }
  .posts_nav a:nth-child(2) {
    float: right; }

/*.block_article {

	.attachment-post-thumbnail {
		@include media-breakpoint-up(md) {
			float: right;
			margin: 0 0 10px 10px;
			width: 45%;
		}
	}

}*/
p.comment-form-url {
  display: none; }

p.form-submit .submit {
  font-size: 1rem; }

.comments-area {
  margin-top: 2em;
  padding-top: 2em;
  border-top: 1px solid #ccc; }

.comments-title {
  margin: 0 0 1em;
  font-family: "Montserrat", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600; }

.comments-list > .comment {
  margin-bottom: 2em;
  padding: 2em;
  background-color: #fff;
  border: 1px solid #ccc; }
  .comments-list > .comment .children > li:not(:last-child) {
    margin-bottom: 1em; }
  .comments-list > .comment .children .children > li:nth-child(odd) {
    background-color: #fafafa; }

.comments-list .comment-respond {
  padding: 1em;
  background-color: #fafafa; }

.comment {
  padding: 1em 2em 0.5em; }

.comment-body {
  padding: 0 1em 1em; }

.comment-content p:last-of-type {
  margin-bottom: 0; }

.comment.depth-2 {
  border-left: 2px solid #333; }

.comment.depth-3 {
  border-left: 2px solid #ccc; }

.comment-meta {
  margin-bottom: 0.5em;
  padding-bottom: 0.5em;
  border-bottom: 1px solid #ccc; }

.comment-metadata a {
  font-size: 14px;
  text-decoration: none;
  cursor: default;
  color: #343a40; }

.comment-reply-link {
  margin-top: 0.5em;
  font-size: 0.925rem !important;
  font-weight: 600 !important; }

.comment-respond {
  margin: 0 0 1em;
  padding: 2em;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #fff; }
  .comment-respond .comment-reply-title {
    margin-top: 0;
    font-size: 1.2rem;
    font-family: "Montserrat", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 800;
    text-transform: uppercase; }
    .comment-respond .comment-reply-title small {
      display: block;
      margin-top: 0.5em; }
      .comment-respond .comment-reply-title small a {
        font-weight: 400 !important;
        text-transform: capitalize !important; }

.branding-logo {
  display: block;
  overflow: visible;
  width: 100%;
  max-width: 240px; }
  .branding-logo img {
    display: block;
    width: 100%;
    margin: 0; }

[class*="btn"] {
  display: inline-block;
  padding: 12px 15px;
  font-family: "Montserrat", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 900;
  line-height: 1em;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 3px;
  border: 1px solid #117a8b;
  background-color: #17a2b8;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  line-height: 1em;
  cursor: pointer; }
  [class*="btn"]:link, [class*="btn"]:visited, [class*="btn"]:active {
    text-decoration: none;
    border: 1px solid #117a8b;
    background-color: #17a2b8;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  [class*="btn"]:hover, [class*="btn"]:focus {
    background-color: #2acbe4;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  [class*="btn"] small {
    display: block;
    line-height: 1em; }
  [class*="btn"]:focus {
    outline: 4px solid rgba(0, 0, 0, 0.25); }

.comment-reply-link, .btn-text {
  display: inline-block;
  padding: 12px 15px;
  font-family: "Montserrat", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 900;
  line-height: 1em;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 3px;
  border: 1px solid #1d2124;
  background-color: #343a40;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .comment-reply-link:link, .comment-reply-link:visited, .comment-reply-link:active, .btn-text:link, .btn-text:visited, .btn-text:active {
    text-decoration: none;
    border: 1px solid #1d2124;
    background-color: #343a40;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .comment-reply-link:hover, .comment-reply-link:focus, .btn-text:hover, .btn-text:focus {
    background-color: #515a63;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn-link {
  display: inline-block;
  padding: 12px 15px;
  font-family: "Montserrat", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 900;
  line-height: 1em;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 3px;
  border: 1px solid #117a8b;
  background-color: #17a2b8;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn-link:link, .btn-link:visited, .btn-link:active {
    text-decoration: none;
    border: 1px solid #117a8b;
    background-color: #17a2b8;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .btn-link:hover, .btn-link:focus {
    background-color: #2acbe4;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn-primary {
  display: inline-block;
  padding: 12px 15px;
  font-family: "Montserrat", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 900;
  line-height: 1em;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 3px;
  border: 1px solid #317f21;
  background-color: #41A72C;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn-primary:link, .btn-primary:visited, .btn-primary:active {
    text-decoration: none;
    border: 1px solid #317f21;
    background-color: #41A72C;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .btn-primary:hover, .btn-primary:focus {
    background-color: #5cce45;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.posts_nav a, p.form-submit .submit, .btn-secondary {
  display: inline-block;
  padding: 12px 15px;
  font-family: "Montserrat", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 900;
  line-height: 1em;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 3px;
  border: 1px solid black;
  background-color: black;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .posts_nav a:link, p.form-submit .submit:link, .posts_nav a:visited, p.form-submit .submit:visited, .posts_nav a:active, p.form-submit .submit:active, .btn-secondary:link, .btn-secondary:visited, .btn-secondary:active {
    text-decoration: none;
    border: 1px solid black;
    background-color: black;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .posts_nav a:hover, p.form-submit .submit:hover, .posts_nav a:focus, p.form-submit .submit:focus, .btn-secondary:hover, .btn-secondary:focus {
    background-color: #202020;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn-highlight {
  display: inline-block;
  padding: 12px 15px;
  font-family: "Montserrat", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 900;
  line-height: 1em;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 3px;
  border: 1px solid #017da6;
  background-color: #01A3D9;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn-highlight:link, .btn-highlight:visited, .btn-highlight:active {
    text-decoration: none;
    border: 1px solid #017da6;
    background-color: #01A3D9;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .btn-highlight:hover, .btn-highlight:focus {
    background-color: #1cc5fe;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn-accent {
  display: inline-block;
  padding: 12px 15px;
  font-family: "Montserrat", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 900;
  line-height: 1em;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 3px;
  border: 1px solid #d1b80d;
  background-color: #F1D620;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn-accent:link, .btn-accent:visited, .btn-accent:active {
    text-decoration: none;
    border: 1px solid #d1b80d;
    background-color: #F1D620;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .btn-accent:hover, .btn-accent:focus {
    background-color: #f3dd44;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn-success {
  display: inline-block;
  padding: 12px 15px;
  font-family: "Montserrat", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 900;
  line-height: 1em;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 3px;
  border: 1px solid #1e7e34;
  background-color: #28a745;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn-success:link, .btn-success:visited, .btn-success:active {
    text-decoration: none;
    border: 1px solid #1e7e34;
    background-color: #28a745;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .btn-success:hover, .btn-success:focus {
    background-color: #3ed160;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.comment-respond .comment-reply-title small a, .btn-danger {
  display: inline-block;
  padding: 12px 15px;
  font-family: "Montserrat", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 900;
  line-height: 1em;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 3px;
  border: 1px solid #bd2130;
  background-color: #dc3545;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .comment-respond .comment-reply-title small a:link, .comment-respond .comment-reply-title small a:visited, .comment-respond .comment-reply-title small a:active, .btn-danger:link, .btn-danger:visited, .btn-danger:active {
    text-decoration: none;
    border: 1px solid #bd2130;
    background-color: #dc3545;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .comment-respond .comment-reply-title small a:hover, .comment-respond .comment-reply-title small a:focus, .btn-danger:hover, .btn-danger:focus {
    background-color: #e56b77;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn-info {
  display: inline-block;
  padding: 12px 15px;
  font-family: "Montserrat", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 900;
  line-height: 1em;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 3px;
  border: 1px solid #510bc4;
  background-color: #6610f2;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn-info:link, .btn-info:visited, .btn-info:active {
    text-decoration: none;
    border: 1px solid #510bc4;
    background-color: #6610f2;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .btn-info:hover, .btn-info:focus {
    background-color: #8d4cf5;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn-review {
  display: inline-block;
  padding: 12px 15px;
  font-family: "Montserrat", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 900;
  line-height: 1em;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 3px;
  border: 1px solid #dc6502;
  background-color: #fd7e14;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn-review:link, .btn-review:visited, .btn-review:active {
    text-decoration: none;
    border: 1px solid #dc6502;
    background-color: #fd7e14;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .btn-review:hover, .btn-review:focus {
    background-color: #fea153;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn-warning {
  display: inline-block;
  padding: 12px 15px;
  font-family: "Montserrat", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 900;
  line-height: 1em;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 3px;
  border: 1px solid #d39e00;
  background-color: #ffc107;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn-warning:link, .btn-warning:visited, .btn-warning:active {
    text-decoration: none;
    border: 1px solid #d39e00;
    background-color: #ffc107;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .btn-warning:hover, .btn-warning:focus {
    background-color: #ffd147;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn-block {
  display: block;
  text-align: center; }

.btn-icon {
  vertical-align: bottom; }
  .btn-icon img {
    display: inline-block;
    width: 22px;
    margin-right: 0.75em;
    vertical-align: middle; }
  .btn-icon.btn--lg img {
    width: 28px; }
  .btn-icon.btn--sm img {
    width: 18px; }

.posts_nav a, p.form-submit .submit, .comment-reply-link, .comment-respond .comment-reply-title small a, .btn--sm {
  padding: 8px 12px; }

.btn--md {
  padding: 12px 15px; }

.btn--lg {
  padding: 15px 20px; }

.btn--wide {
  display: block;
  width: 100%; }

.button_close {
  padding: 5px;
  width: 24px;
  height: 24px;
  font-size: 24px;
  line-height: 1em;
  text-align: center;
  border-radius: 3px;
  border: 1px solid #bd2130;
  background-color: #dc3545;
  background-image: url("/_/images/icons/close.png");
  background-size: 14px 14px;
  background-position: center center;
  background-repeat: no-repeat;
  color: #fff;
  cursor: pointer; }
  .button_close:hover {
    border-color: #dc3545;
    background-color: #e4606d; }

[class*="btn-icon"] {
  padding: 15px 15px 15px 45px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1em;
  text-transform: uppercase;
  background-position: 12px 9px;
  background-repeat: no-repeat;
  background-size: 24px; }

.btn-icon--calendar {
  background-image: url("/_/images/icons/btn-calendar.png"); }
  .btn-icon--calendar:hover {
    background-image: url("/_/images/icons/btn-calendar-hover.png"); }

.btn-icon--map {
  background-image: url("/_/images/icons/btn-map.png"); }
  .btn-icon--map:hover {
    background-image: url("/_/images/icons/btn-map-hover.png"); }

.btn-icon--review {
  background-image: url("/_/images/icons/btn-review.png"); }
  .btn-icon--review:hover {
    background-image: url("/_/images/icons/btn-review-hover.png"); }

.button_mobile {
  text-align: left;
  line-height: 1em;
  background: transparent; }
  .button_mobile .mobile_toggle {
    width: 48px;
    height: 41px;
    padding: 10px 11px 9px;
    background-color: #060301;
    border: none;
    border-radius: 4px;
    color: #fff; }
    .button_mobile .mobile_toggle:hover, .button_mobile .mobile_toggle:focus {
      outline: none;
      background-color: #321908;
      cursor: pointer; }
  .button_mobile .button-bars {
    display: inline-block;
    height: 100%;
    width: 26px; }
  .button_mobile .icon-bar {
    -webkit-transition: transform ease-in-out 300ms;
    -moz-transition: transform ease-in-out 300ms;
    -ms-transition: transform ease-in-out 300ms;
    -o-transition: transform ease-in-out 300ms;
    transition: transform ease-in-out 300ms;
    display: block;
    width: 100%;
    height: 3px;
    border-radius: 4px;
    background-color: #fff; }
    .button_mobile .icon-bar:nth-child(2) {
      margin: 5px 0; }
  .button_mobile b {
    display: none; }
  .canvas-slid .button_mobile .icon-bar:nth-child(2) {
    visibility: hidden; }
  .canvas-slid .button_mobile .icon-bar:nth-child(1), .canvas-slid .button_mobile .icon-bar:nth-child(3) {
    -webkit-transition: transform ease-in-out 150ms;
    -moz-transition: transform ease-in-out 150ms;
    -ms-transition: transform ease-in-out 150ms;
    -o-transition: transform ease-in-out 150ms;
    transition: transform ease-in-out 150ms; }
  .canvas-slid .button_mobile .icon-bar:nth-child(1) {
    transform: rotate(45deg);
    margin-top: 9px; }
  .canvas-slid .button_mobile .icon-bar:nth-child(3) {
    transform: rotate(-45deg);
    margin-top: -16px; }

.button_conversion {
  display: inline-block;
  padding: 15px 20px 15px 55px;
  font-family: "Montserrat", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.125rem;
  font-weight: 900;
  line-height: 1em;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 3px;
  border: 1px solid #bd2130;
  background-color: #dc3545;
  background-image: url("/_/images/icons/truck.png");
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 55px auto;
  color: #fff;
  box-shadow: 0 22px 9px -20px rgba(0, 0, 0, 0.7);
  transform: scale(0.925);
  transition: none;
  transition-timing-function: linear;
  transition-duration: 300ms, 300ms, 200ms;
  transition-property: background-position, padding, transform, box-shadow;
  transition-delay: 0ms, 0ms, 0ms, 0ms; }
  .button_conversion:link, .button_conversion:visited, .button_conversion:active {
    text-decoration: none;
    border: 1px solid #bd2130;
    background-color: #dc3545;
    color: #fff; }
  .button_conversion:hover, .button_conversion:focus {
    padding: 15px 55px 15px 20px;
    background-color: #e04b59;
    background-position: right center;
    box-shadow: 0 22px 9px -20px transparent;
    color: #fff;
    transform: scale(1);
    transition-duration: 600ms, 300ms, 100ms, 300ms;
    transition-delay: 200ms, 100ms, 0ms, 300ms; }
  @media (min-width: 992px) {
    .button_conversion {
      font-size: 1.3rem; } }
  @media (min-width: 1200px) {
    .button_conversion {
      font-size: 1.5rem; } }

.info_company {
  font-size: 0.85rem;
  line-height: 1.3em; }
  .info_company strong {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1em; }
    .info_company strong small {
      display: block;
      font-weight: 400; }
  .info_company .phone {
    font-size: 1.1rem;
    font-weight: 800;
    line-height: 1.3em;
    color: #222; }
    .info_company .phone span {
      display: inline-block;
      position: relative;
      vertical-align: top;
      top: -0.05em;
      font-size: 0.85rem;
      font-weight: 600;
      color: #fff; }
  .info_company .company-list {
    margin-left: 0;
    padding-left: 0;
    list-style-type: none;
    list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); }
    .info_company .company-list > li:first-child,
    .info_company .company-list > li:nth-child(3) {
      margin-bottom: 5px; }

.contact-call {
  line-height: 1em; }

.contact-title {
  display: block;
  font-size: 0.9rem;
  font-weight: 600; }

.contact-phone {
  display: inline-block;
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 1em;
  letter-spacing: -0.04em;
  color: #1d2124; }

.contact-vanity {
  display: block;
  font-size: 0.9rem; }

.info_copyright {
  font-size: 0.85rem;
  line-height: 1.3em; }
  .info_copyright a {
    text-decoration: none;
    color: #fff; }
  .info_copyright .copyright-list {
    margin-left: 0;
    padding-left: 0;
    list-style-type: none;
    list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); }

.coverage {
  padding: 30px;
  background-color: #f8f9fa; }
  .coverage .coverage_title {
    margin: 0 0 0.75rem;
    font-size: 1rem;
    font-weight: 800;
    text-transform: uppercase;
    color: #060301; }
  .coverage p {
    margin-bottom: 0.5rem;
    line-height: 1.325em; }
    .coverage p:last-of-type {
      margin-bottom: 0; }
  .coverage .coverage_county {
    font-size: 1rem;
    font-weight: 600; }
  .coverage .coverage_city {
    font-size: 0.825rem; }

.block.coverage {
  border: none;
  border-top: 1px solid #dee2e6;
  background-color: #fff;
  padding: 2em 0 1.75em;
  text-align: center;
  box-shadow: none; }
  .block.coverage .row {
    justify-content: center; }
  @media (min-width: 992px) {
    .block.coverage .wrap {
      flex: 0 0 83.33333%;
      max-width: 83.33333%; } }
  @media (min-width: 1200px) {
    .block.coverage .wrap {
      flex: 0 0 75%;
      max-width: 75%; } }

.faq_jump {
  margin-bottom: 2em; }
  .faq_jump li {
    line-height: 1em !important; }
  .faq_jump li:nth-child(even) {
    background-color: #fff; }
  .faq_jump > li:not(:last-child) {
    margin-bottom: 0 !important;
    border-bottom: 1px solid #ddd; }
  .faq_jump a {
    display: inline-block;
    padding: 0.75em;
    font-size: 0.95rem;
    line-height: 1em;
    text-decoration: none;
    color: #343a40; }
    .faq_jump a:hover {
      font-weight: 700;
      color: #41A72C; }

.faq_group .faq_item:nth-child(even) {
  background-color: white; }

.faq_item {
  padding: 2em;
  border-bottom: 1px solid #ddd;
  text-align: right; }
  .faq_item h2, .faq_item p {
    text-align: left; }
  .faq_item .faq--title {
    margin: 0 0 0.5em;
    font-size: 1.2rem;
    letter-spacing: 0; }

.form {
  font-size: 1rem;
  font-family: "Montserrat", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1em; }

.form_group {
  margin: 0 0 0.75em;
  padding: 0; }
  .form_group.form_hp {
    display: none; }
  .form_group.form_clear {
    clear: both; }

label {
  display: block;
  margin: 0 0 1px 2px;
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1em;
  letter-spacing: -0.0275em; }

.form_instruct, .form_example {
  margin-left: 1px;
  line-height: 1.275em; }

.form_instruct {
  font-size: 0.95rem;
  margin-top: 0.25em;
  margin-bottom: 0.25em; }

.form_example {
  margin-top: 0.25em;
  font-size: 0.85rem; }

.form_control {
  display: block;
  width: 100%;
  margin: 0 0 5px;
  padding: 9px 7px;
  font-size: 1.1rem;
  line-height: 1em;
  border-radius: 2px;
  border: 1px solid #ddd;
  background-color: #fff;
  outline: none; }
  .form_control:focus {
    border-color: #ffc107;
    background-color: #fff4d3; }

textarea.form_control {
  min-height: 150px;
  line-height: 1.275em; }

.form_error .form_control {
  border-radius: 2px 2px 0 0;
  border-color: #dc3545 !important; }

.error-message {
  margin: -5px 0 0;
  padding: 6px 4px 4px;
  font-size: 0.7em;
  font-weight: 800;
  line-height: 1em;
  text-transform: uppercase;
  background-color: #dc3545;
  color: #fff; }

.form_section {
  margin-bottom: 30px;
  padding: 15px;
  border: 2px groove #eeeeee; }
  .form_section legend {
    padding: 8px 15px;
    font-weight: 800; }
  .form_section label {
    margin-bottom: 5px; }

.form_newsletter {
  border: none;
  overflow: hidden; }
  @media (min-width: 576px) {
    .form_newsletter .form-email {
      float: left;
      width: 75%;
      padding-left: 0; }
      .form_newsletter .form-email .form-control {
        padding: 11px 6px; }
    .form_newsletter .form-submit {
      float: right;
      width: 25%;
      padding-left: 0; }
    .form_newsletter .btn {
      margin-top: 6px;
      padding: 8px 16px; }
      .form_newsletter .btn:hover {
        padding: 10px 16px; } }

.form_quote .form_zip {
  float: left;
  width: 49.5%; }
  .form_quote .form_zip.form_destination {
    float: right; }

@media (min-width: 768px) and (max-width: 991px) {
  .form_quote .form_left {
    float: left;
    width: 49.5%; }
  .form_quote .form_right {
    float: right;
    width: 49.5%; } }

@media (min-width: 1200px) {
  .form_quote .form_left {
    float: left;
    width: 49.5%; }
  .form_quote .form_right {
    float: right;
    width: 49.5%; } }

.form_review .review_text {
  margin-bottom: 1em; }
  .form_review .review_text .form_control {
    height: 200px; }

.radio_group {
  margin-bottom: 1em;
  line-height: 1em; }
  .radio_group > label {
    position: relative;
    display: inline-block;
    margin-right: 0.5em;
    min-width: 120px;
    vertical-align: baseline;
    top: -0.35em; }
  .radio_group .error-message {
    clear: both;
    margin-top: 0.75em; }

.rating_recommend p {
  margin: 0.4em 0 0;
  padding: 0; }

.rating_recommend .error-message {
  clear: both;
  margin-top: 0.75em; }

.rating_recommend .radio_yn,
.rating_recommend .radio_yn-label {
  box-sizing: border-box;
  display: inline-block;
  width: 70px;
  height: 36px;
  margin: 0 0 0 -5px;
  padding: 0.3em 0;
  font-size: 1.3rem;
  line-height: 1em;
  text-align: left;
  vertical-align: top;
  border: 1px solid #ccc;
  outline: none;
  cursor: pointer; }
  @media (min-width: 768px) {
    .rating_recommend .radio_yn,
    .rating_recommend .radio_yn-label {
      height: 39px; } }

.rating_recommend .radio_yn:checked + .radio_yn-label {
  background-color: #41A72C !important;
  color: #fff; }

.rating_recommend .radio_yn:hover + .radio_yn-label {
  background-color: #aaa; }

.rating_recommend .radio_yn-label {
  margin-left: -70px;
  background-color: #fff;
  text-align: center; }

.rating {
  overflow: hidden;
  display: inline-block; }

.rating-input {
  float: right;
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0 0 0 -24px;
  opacity: 0; }

.rating-star {
  cursor: pointer;
  position: relative;
  float: right;
  display: block;
  width: 28px;
  height: 25px;
  background: url("/_/images/icons/star-rating.png") 0 -24px no-repeat; }

.rating-star:hover,
.rating-star:hover ~ .rating-star,
.rating-input:checked ~ .rating-star {
  background-position: 0 0; }

.gallery {
  overflow: hidden;
  margin-bottom: 1em; }

.gallery_sidebar img {
  margin-bottom: 0.5em; }

.gallery_lightbox img {
  padding: 4px;
  border: 1px solid #ccc;
  background-color: #fff; }

.gallery_lightbox > a {
  margin-bottom: 1%; }

@media (max-width: 575px) {
  .g2-xs > img, .g2-xs > a, .g2-xs > .img-captioned {
    display: block;
    float: left;
    width: 49.5%; }
    .g2-xs > img:nth-child(odd), .g2-xs > a:nth-child(odd), .g2-xs > .img-captioned:nth-child(odd) {
      clear: both; }
    .g2-xs > img:nth-child(even), .g2-xs > a:nth-child(even), .g2-xs > .img-captioned:nth-child(even) {
      float: right; } }

@media (min-width: 576px) and (max-width: 767px) {
  .g2-sm > img, .g2-sm > a, .g2-sm > .img-captioned {
    display: block;
    float: left;
    width: 49.5%; }
    .g2-sm > img:nth-child(odd), .g2-sm > a:nth-child(odd), .g2-sm > .img-captioned:nth-child(odd) {
      clear: both; }
    .g2-sm > img:nth-child(even), .g2-sm > a:nth-child(even), .g2-sm > .img-captioned:nth-child(even) {
      float: right; } }

@media (min-width: 768px) and (max-width: 991px) {
  .g2-md > img, .g2-md > a, .g2-md > .img-captioned {
    display: block;
    float: left;
    width: 49.5%; }
    .g2-md > img:nth-child(odd), .g2-md > a:nth-child(odd), .g2-md > .img-captioned:nth-child(odd) {
      clear: both; }
    .g2-md > img:nth-child(even), .g2-md > a:nth-child(even), .g2-md > .img-captioned:nth-child(even) {
      float: right; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .g2-lg > img, .g2-lg > a, .g2-lg > .img-captioned {
    display: block;
    float: left;
    width: 49.5%; }
    .g2-lg > img:nth-child(odd), .g2-lg > a:nth-child(odd), .g2-lg > .img-captioned:nth-child(odd) {
      clear: both; }
    .g2-lg > img:nth-child(even), .g2-lg > a:nth-child(even), .g2-lg > .img-captioned:nth-child(even) {
      float: right; } }

@media (min-width: 1200px) {
  .g2-xl > img, .g2-xl > a, .g2-xl > .img-captioned {
    display: block;
    float: left;
    width: 49.5%; }
    .g2-xl > img:nth-child(odd), .g2-xl > a:nth-child(odd), .g2-xl > .img-captioned:nth-child(odd) {
      clear: both; }
    .g2-xl > img:nth-child(even), .g2-xl > a:nth-child(even), .g2-xl > .img-captioned:nth-child(even) {
      float: right; } }

@media (min-width: 576px) and (max-width: 767px) {
  .g3-sm > img, .g3-sm > a, .g3-sm > .img-captioned {
    display: block;
    float: left;
    width: 32.66667%;
    margin-right: 1%; }
    .g3-sm > img:nth-child(3n-2), .g3-sm > a:nth-child(3n-2), .g3-sm > .img-captioned:nth-child(3n-2) {
      clear: both; }
    .g3-sm > img:nth-child(3n), .g3-sm > a:nth-child(3n), .g3-sm > .img-captioned:nth-child(3n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 768px) and (max-width: 991px) {
  .g3-md > img, .g3-md > a, .g3-md > .img-captioned {
    display: block;
    float: left;
    width: 32.66667%;
    margin-right: 1%; }
    .g3-md > img:nth-child(3n-2), .g3-md > a:nth-child(3n-2), .g3-md > .img-captioned:nth-child(3n-2) {
      clear: both; }
    .g3-md > img:nth-child(3n), .g3-md > a:nth-child(3n), .g3-md > .img-captioned:nth-child(3n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .g3-lg > img, .g3-lg > a, .g3-lg > .img-captioned {
    display: block;
    float: left;
    width: 32.66667%;
    margin-right: 1%; }
    .g3-lg > img:nth-child(3n-2), .g3-lg > a:nth-child(3n-2), .g3-lg > .img-captioned:nth-child(3n-2) {
      clear: both; }
    .g3-lg > img:nth-child(3n), .g3-lg > a:nth-child(3n), .g3-lg > .img-captioned:nth-child(3n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 1200px) {
  .g3-xl > img, .g3-xl > a, .g3-xl > .img-captioned {
    display: block;
    float: left;
    width: 32.66667%;
    margin-right: 1%; }
    .g3-xl > img:nth-child(3n-2), .g3-xl > a:nth-child(3n-2), .g3-xl > .img-captioned:nth-child(3n-2) {
      clear: both; }
    .g3-xl > img:nth-child(3n), .g3-xl > a:nth-child(3n), .g3-xl > .img-captioned:nth-child(3n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 576px) and (max-width: 767px) {
  .g4-sm > img, .g4-sm > a, .g4-sm > .img-captioned {
    display: block;
    float: left;
    width: 24.25%;
    margin-right: 1%; }
    .g4-sm > img:nth-child(4n-3), .g4-sm > a:nth-child(4n-3), .g4-sm > .img-captioned:nth-child(4n-3) {
      clear: both; }
    .g4-sm > img:nth-child(4n), .g4-sm > a:nth-child(4n), .g4-sm > .img-captioned:nth-child(4n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 768px) and (max-width: 991px) {
  .g4-md > img, .g4-md > a, .g4-md > .img-captioned {
    display: block;
    float: left;
    width: 24.25%;
    margin-right: 1%; }
    .g4-md > img:nth-child(4n-3), .g4-md > a:nth-child(4n-3), .g4-md > .img-captioned:nth-child(4n-3) {
      clear: both; }
    .g4-md > img:nth-child(4n), .g4-md > a:nth-child(4n), .g4-md > .img-captioned:nth-child(4n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .g4-lg > img, .g4-lg > a, .g4-lg > .img-captioned {
    display: block;
    float: left;
    width: 24.25%;
    margin-right: 1%; }
    .g4-lg > img:nth-child(4n-3), .g4-lg > a:nth-child(4n-3), .g4-lg > .img-captioned:nth-child(4n-3) {
      clear: both; }
    .g4-lg > img:nth-child(4n), .g4-lg > a:nth-child(4n), .g4-lg > .img-captioned:nth-child(4n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 1200px) {
  .g4-xl > img, .g4-xl > a, .g4-xl > .img-captioned {
    display: block;
    float: left;
    width: 24.25%;
    margin-right: 1%; }
    .g4-xl > img:nth-child(4n-3), .g4-xl > a:nth-child(4n-3), .g4-xl > .img-captioned:nth-child(4n-3) {
      clear: both; }
    .g4-xl > img:nth-child(4n), .g4-xl > a:nth-child(4n), .g4-xl > .img-captioned:nth-child(4n) {
      float: right;
      margin-right: 0; } }

.gallery_featured {
  padding: 1em;
  line-height: 1em;
  border: 1px solid #ccc;
  background-color: #fff; }
  .gallery_featured p {
    line-height: 1.6em; }
  @media (max-width: 575px) {
    .gallery_featured {
      margin-bottom: 1em; } }
  @media (min-width: 576px) {
    .gallery_featured {
      padding-right: 60px; } }
  @media (min-width: 768px) {
    .gallery_featured {
      margin-bottom: -7px;
      overflow: hidden; }
      .gallery_featured .gallery_cover {
        float: left;
        width: 55%;
        padding-right: 0.5em; }
      .gallery_featured .gallery_info {
        float: left;
        width: 45%;
        padding-left: 0.5em; }
      .gallery_featured .cover_thumbs {
        margin-bottom: 0; } }

.gallery_title {
  margin: 0.5em 0 0.5em;
  font-size: 1.5rem;
  font-weight: 800;
  font-style: italic;
  letter-spacing: -0.0375em;
  line-height: 1em;
  text-transform: uppercase; }

.cover_thumbs {
  overflow: hidden;
  margin: 1% 0 1em; }
  .cover_thumbs > a {
    -webkit-transition: opacity ease-in 300ms;
    -moz-transition: opacity ease-in 300ms;
    -ms-transition: opacity ease-in 300ms;
    -o-transition: opacity ease-in 300ms;
    transition: opacity ease-in 300ms;
    display: block;
    float: left;
    width: 24.25%;
    margin-right: 1%;
    opacity: 0.5; }
    .cover_thumbs > a:last-child {
      margin-right: 0; }
    .cover_thumbs > a:hover {
      -webkit-transition: opacity ease-in 300ms;
      -moz-transition: opacity ease-in 300ms;
      -ms-transition: opacity ease-in 300ms;
      -o-transition: opacity ease-in 300ms;
      transition: opacity ease-in 300ms;
      opacity: 1.0; }

.banner-hours {
  text-decoration: none;
  color: #999; }
  .banner-hours:link, .banner-hours:active, .banner-hours:visited, .banner-hours:focus {
    color: #999; }
  .banner-hours:hover {
    color: #999; }
  .banner-hours b {
    font-weight: 400; }

.list-hours .hours-title {
  padding: 0 0.5em 0.5em;
  font-size: 1.1rem;
  font-weight: 800;
  line-height: 1em;
  border-bottom: 2px solid #343a40; }

.list-hours .hours-item {
  padding: 0.25em 0.5em;
  font-size: 0.95rem; }
  .list-hours .hours-item:hover {
    background-color: rgba(52, 58, 64, 0.1);
    cursor: default; }
  .list-hours .hours-item.active {
    font-weight: 600;
    background-color: rgba(6, 3, 1, 0.2); }

.list-hours li:not(:last-child):not(.hours-title) {
  border-bottom: 1px solid #ccc; }

.list-hours b {
  display: inline-block;
  width: 60px; }

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99000;
  background-color: black;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  display: none; }

.lightbox {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 99500;
  text-align: center;
  line-height: 0;
  font-weight: 400; }
  @media (min-width: 768px) {
    .lightbox {
      margin-top: 75px; } }

.lightbox .lb-image {
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
  border-radius: 3px; }

.lightbox a img {
  border: none; }

.lb-outerContainer {
  position: relative;
  padding: 5px;
  overflow: hidden;
  background-color: white;
  *zoom: 1;
  margin: 0 auto;
  border-radius: 3px 3px 0 0; }

.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both; }

.lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0; }

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url("/_/images/layout/loading.gif") no-repeat; }

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10; }

.lb-container > .nav {
  left: 0; }

.lb-nav a {
  outline: none;
  background-image: url("data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="); }

.lb-prev, .lb-next {
  height: 100%;
  cursor: pointer;
  display: block; }

.lb-nav a.lb-prev {
  width: 34%;
  left: 0;
  float: left;
  background: url("/_/images/icons/lb_prev.png") left 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -ms-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s; }

.lb-nav a.lb-prev:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.lb-nav a.lb-next {
  width: 64%;
  right: 0;
  float: right;
  background: url("/_/images/icons/lb_next.png") right 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -ms-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s; }

.lb-nav a.lb-next:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.lb-dataContainer {
  margin: 0 auto;
  padding: 7px 0;
  *zoom: 1;
  width: 100%;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: #fff;
  color: #343a40; }

.lb-dataContainer:after {
  content: "";
  display: table;
  clear: both; }

.lb-data {
  padding: 0 4px; }

.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em; }

.lb-data .lb-caption {
  display: inline-block;
  padding: 6px 5px 0;
  font-size: 1rem;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1em; }

.lb-data .lb-number {
  display: none !important;
  visibility: hidden !important; }

.lb-data .lb-close {
  display: block;
  float: right;
  margin-right: 5px;
  width: 30px;
  height: 30px;
  background: url("/_/images/icons/close-red.png") center center no-repeat;
  background-size: 20px 20px;
  text-align: right;
  outline: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -ms-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s; }

.lb-data .lb-close:hover {
  cursor: pointer;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.media_object {
  margin: 0 0 1em;
  padding: 30px;
  border: 1px solid #ccc; }
  @media (min-width: 768px) {
    .media_object .media_content {
      display: flex; }
    .media_object .object_media {
      padding-right: 1em; } }

.media-title {
  margin: -30px -30px 30px;
  padding: 25px 30px;
  background-color: #eaeaea; }

.object_text > *:last-child {
  margin-bottom: 0; }

.team_member {
  background-color: rgba(255, 255, 255, 0.7); }
  @media (max-width: 767px) {
    .team_member .member_media {
      float: right;
      margin: 0 0 15px 15px; } }
  @media (min-width: 768px) {
    .team_member .member_media {
      padding-right: 2em; } }
  @media (min-width: 992px) {
    .team_member .member_media {
      padding: 0 4em 0 2em; } }
  @media (min-width: 1200px) {
    .team_member .member_media {
      padding: 0 6em 0 4em; } }

body.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto; }

.modal {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 99000;
  cursor: pointer; }
  .modal.show {
    display: block; }

.modal-dialog {
  position: relative;
  z-index: 99500;
  width: 90%;
  max-width: 640px;
  margin: 20px auto;
  padding: 15px;
  border-radius: 6px;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: default; }
  .modal-dialog p {
    margin-bottom: 0;
    padding: 10px 5px;
    font-size: 14px;
    line-height: 1.25em; }
    .modal-dialog p:nth-child(even) {
      background-color: #ddd; }

.modal-header {
  margin: 0 0 15px;
  padding: 0 0 10px;
  border-bottom: 1px solid #ccc; }

.modal-title {
  margin: 15px 0 0;
  font-size: 18px; }

.menu {
  overflow: hidden;
  margin: 0;
  padding: 0; }

.menu_item {
  box-sizing: border-box;
  list-style-type: none; }
  .menu_item.active .menu_link {
    cursor: default; }
    .menu_item.active .menu_link:hover {
      cursor: default; }
  .menu_item.open .dropdown_menu {
    display: block;
    height: auto; }
  .menu_item.open .caret {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg); }

.menu_link {
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms;
  display: block;
  padding: 12px 15px;
  line-height: 1em;
  text-decoration: none; }
  .menu_link:hover, .menu_link:focus {
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms;
    outline: none; }

.caret {
  display: inline-block;
  position: relative;
  vertical-align: baseline;
  width: 0.7em;
  height: 0.7em;
  background-image: url("/_/images/icons/dropdown-white.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center; }

.dropdown-toggle:hover .caret,
.open .dropdown-toggle .caret {
  background-image: url("/_/images/icons/dropdown-white.svg"); }

.dropdown_menu {
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms;
  display: none;
  position: relative;
  z-index: 95000;
  margin: 0;
  padding: 1em;
  min-width: 280px; }
  .dropdown_menu .menu_item {
    list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
    text-align: left; }
  .dropdown_menu .menu_link {
    padding-left: 10px; }
    .dropdown_menu .menu_link:hover, .dropdown_menu .menu_link:focus {
      outline: none; }
  @media (min-width: 768px) {
    .dropdown_menu {
      position: absolute; } }

.banner_menu {
  padding: 40px 0 10px;
  text-align: center;
  background-color: #fff;
  background-image: url("/_/images/layout/line-secondary.png");
  background-size: 1px 5px;
  background-repeat: repeat-x;
  background-position: center 55px; }
  .banner_menu .container {
    padding: 0; }

.banner-menu {
  line-height: 1em; }
  .banner-menu .menu {
    display: inline-block;
    padding: 0 3px;
    text-align: center;
    background-color: #fff; }
  .banner-menu .menu_item {
    display: inline-block;
    padding: 0 3px;
    background-color: #fff; }
    .banner-menu .menu_item.active .menu_link {
      font-size: 16px;
      font-weight: 800;
      letter-spacing: -0.035em;
      background-color: #fff;
      color: #01A3D9; }
  .banner-menu .menu_link {
    padding: 10px 15px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1em;
    text-transform: uppercase;
    border-radius: 3px;
    background-color: #fff;
    color: #343a40; }
    .banner-menu .menu_link:hover {
      background-color: #41A72C;
      color: #fff; }

.banner-book {
  padding: 0 18px;
  background-color: #fff; }
  @media (min-width: 992px) {
    .banner-book .btn {
      padding: 18px 20px 18px 55px;
      font-size: 16px;
      background-size: 28px;
      background-position: 13px 11px; } }

.nav_mobile {
  display: none;
  position: fixed;
  z-index: 999999;
  top: 0;
  right: auto;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  width: 90%;
  max-width: 320px;
  height: 100%;
  padding: 30px 15px;
  border-right: 3px solid #fff;
  box-shadow: 2px 0 5px 0 rgba(0, 0, 0, 0.5);
  background-color: #41A72C;
  color: #fff; }
  .nav_mobile.in {
    display: block; }
  @media (min-width: 768px) {
    .nav_mobile {
      display: none !important; } }
  .nav_mobile h4 {
    font-size: 1.2em;
    font-weight: 800;
    text-transform: uppercase; }
  .nav_mobile .nav_menu > .menu_item:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3); }
  .nav_mobile .nav_menu > .menu_item:not(:first-child) {
    border-top: 1px solid rgba(255, 255, 255, 0.2); }
  .nav_mobile .nav_menu > .menu_item.active .menu_link {
    background-color: #fff;
    color: #41A72C; }
    .nav_mobile .nav_menu > .menu_item.active .menu_link:hover, .nav_mobile .nav_menu > .menu_item.active .menu_link:focus {
      outline: none;
      background-color: #fff;
      color: #41A72C; }
  .nav_mobile .nav_menu > .menu_item.open > .menu_link {
    background-color: rgba(255, 255, 255, 0.2); }
  .nav_mobile .nav_menu > .menu_item.open .dropdown_menu {
    background-color: rgba(255, 255, 255, 0.2); }
  .nav_mobile .nav_menu > .menu_item > .menu_link {
    padding: 12px 10px;
    font-weight: 600;
    background-color: transparent;
    color: #fff; }
    .nav_mobile .nav_menu > .menu_item > .menu_link:hover, .nav_mobile .nav_menu > .menu_item > .menu_link:focus {
      outline: none;
      background-color: #060301;
      color: #fff; }
  .nav_mobile .dropdown_menu {
    padding: 0; }
    .nav_mobile .dropdown_menu .menu_item {
      font-size: 0.925em; }
    .nav_mobile .dropdown_menu .menu_link {
      background-color: transparent;
      color: #fff; }
      .nav_mobile .dropdown_menu .menu_link:hover, .nav_mobile .dropdown_menu .menu_link:focus {
        outline: none;
        background-color: rgba(0, 0, 0, 0.2);
        color: #fff; }
  .nav_mobile .mobile-toggle {
    font-size: 1.2em;
    color: #fff;
    text-decoration: none; }
    .nav_mobile .mobile-toggle img {
      display: inline-block;
      width: 0.8em;
      margin-right: 10px; }

.service_menu .menu_item:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2); }

.service_menu .menu_item.active .menu_link {
  background-color: transparent;
  color: #01A3D9;
  font-size: 16px;
  font-weight: 800; }

.service_menu .menu_link {
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  color: #495057; }
  .service_menu .menu_link:hover {
    background-color: #41A72C;
    color: #fff; }

.review cite {
  display: block;
  margin-top: 1em;
  font-size: 1rem;
  font-weight: 600;
  font-style: normal;
  line-height: 1em; }
  .review cite img {
    display: block;
    width: 133px;
    margin: 0 0 10px; }

@media (min-width: 768px) {
  .review cite img {
    display: inline-block;
    width: 120px;
    margin: 0 7px 0 0;
    vertical-align: middle; } }

.reviews_main .review {
  padding: 1em;
  border: 1px solid #ccc;
  background-color: #fff; }
  .reviews_main .review:not(:last-child) {
    margin-bottom: 1em; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

.slick-slide img {
  display: block;
  margin: 0; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("/_/images/layout/ajax-loader.gif") center center no-repeat; }

/* Arrows */
.slick-prev,
.slick-next {
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  z-index: 95000;
  position: absolute;
  display: block;
  height: 100%;
  top: 0;
  bottom: 0;
  width: 55px;
  font-size: 0px;
  line-height: 0px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.8);
  background-size: 50%;
  background-position: center center;
  background-repeat: no-repeat;
  color: transparent;
  padding: 0;
  border: none;
  outline: none;
  opacity: 0; }
  .slick-prev:hover,
  .slick-next:hover {
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    outline: none;
    opacity: 0.6; }

.slick-prev {
  left: 0;
  background-image: url("/_/images/icons/angle-left.svg"); }

.slick-next {
  right: 0;
  background-image: url("/_/images/icons/angle-right.svg"); }

.slideshow .slide_caption {
  line-height: 1em;
  padding: 20px 15px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  background-color: #1d2124;
  color: #fff; }

.slideshow .caption-title {
  margin: 0 0 0.5em;
  font-size: 1.2rem;
  font-weight: 800;
  line-height: 1em; }

.slideshow .caption-text {
  margin: 0;
  font-size: 0.95rem;
  line-height: 1.2em; }

.slideshow .caption-text + .caption-text {
  margin-top: 0.5em; }

@media (max-width: 575px) {
  .slideshow .slick-prev,
  .slideshow .slick-next {
    visibility: hidden; } }

.slider_jumbo {
  margin: 0 auto;
  line-height: 0px; }
  .slider_jumbo:hover .slick-next {
    opacity: 1; }
  .slider_jumbo .slick-prev,
  .slider_jumbo .slick-next {
    z-index: 999999;
    top: auto;
    height: 106px;
    width: 44px;
    background-color: rgba(0, 0, 0, 0.1);
    background-size: 18px;
    opacity: 1; }
    .slider_jumbo .slick-prev:hover,
    .slider_jumbo .slick-next:hover {
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.3); }
  .slider_jumbo .slick-prev {
    background-image: url("/_/images/icons/slide_prev-text.png"); }
  .slider_jumbo .slick-next {
    background-image: url("/_/images/icons/slide_next-text.png"); }
  .slider_jumbo .slide_wrap {
    display: block;
    width: 100%;
    max-width: 1170px;
    max-height: 580px;
    margin: 0 auto;
    text-align: center;
    background-position: center center;
    background-size: cover; }
  .slider_jumbo .slide_caption {
    text-align: center;
    border-top: 5px solid #fff;
    background-color: #317f21; }
    .slider_jumbo .slide_caption .caption-title {
      margin-bottom: 0.25em;
      font-size: 1.5rem; }
    .slider_jumbo .slide_caption .caption-text {
      font-size: 1.15rem;
      font-weight: 600;
      line-height: 1em; }
      .slider_jumbo .slide_caption .caption-text em {
        display: block;
        margin-top: 0.175em;
        font-size: 85%;
        font-weight: 400;
        line-height: 1em; }
  @media (min-width: 768px) {
    .slider_jumbo .slide_01 .slide_wrap {
      background-image: url("/_/images/slides/jumbo/md/01.jpg"); }
    .slider_jumbo .slide_02 .slide_wrap {
      background-image: url("/_/images/slides/jumbo/md/02.jpg"); }
    .slider_jumbo .slide_03 .slide_wrap {
      background-image: url("/_/images/slides/jumbo/md/03.jpg"); }
    .slider_jumbo .slide_04 .slide_wrap {
      background-image: url("/_/images/slides/jumbo/md/04.jpg"); }
    .slider_jumbo .slide_05 .slide_wrap {
      background-image: url("/_/images/slides/jumbo/md/05.jpg"); }
    .slider_jumbo .slide_wrap {
      height: 390px; }
    .slider_jumbo .slide-placeholder {
      visibility: hidden; }
    .slider_jumbo .slide_caption {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center; }
      .slider_jumbo .slide_caption .caption-title {
        margin: 0 15px 0 0;
        text-align: right; }
      .slider_jumbo .slide_caption .caption-text {
        margin: 0;
        padding: 10px 0 10px 15px;
        text-align: left;
        border-left: 1px solid #fff; }
    .slider_jumbo .slick-prev,
    .slider_jumbo .slick-next {
      height: 98px;
      width: 60px; } }
  @media (min-width: 992px) {
    .slider_jumbo .slide_01 .slide_wrap {
      background-image: url("/_/images/slides/jumbo/lg/01.jpg"); }
    .slider_jumbo .slide_02 .slide_wrap {
      background-image: url("/_/images/slides/jumbo/lg/02.jpg"); }
    .slider_jumbo .slide_03 .slide_wrap {
      background-image: url("/_/images/slides/jumbo/lg/03.jpg"); }
    .slider_jumbo .slide_04 .slide_wrap {
      background-image: url("/_/images/slides/jumbo/lg/04.jpg"); }
    .slider_jumbo .slide_05 .slide_wrap {
      background-image: url("/_/images/slides/jumbo/lg/05.jpg"); }
    .slider_jumbo .slide_wrap {
      height: 480px; }
    .slider_jumbo .slide_caption .caption-title {
      font-size: 1.7rem; }
    .slider_jumbo .slide_caption .caption-text {
      font-size: 1.3rem; }
    .slider_jumbo .slick-prev,
    .slider_jumbo .slick-next {
      height: 103px; } }
  @media (min-width: 1200px) {
    .slider_jumbo .slide {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center; }
    .slider_jumbo .slide_01 {
      background-image: url("/_/images/slides/jumbo/bg/01.jpg"); }
    .slider_jumbo .slide_02 {
      background-image: url("/_/images/slides/jumbo/bg/02.jpg"); }
    .slider_jumbo .slide_03 {
      background-image: url("/_/images/slides/jumbo/bg/03.jpg"); }
    .slider_jumbo .slide_04 {
      background-image: url("/_/images/slides/jumbo/bg/04.jpg"); }
    .slider_jumbo .slide_05 {
      background-image: url("/_/images/slides/jumbo/bg/05.jpg"); }
    .slider_jumbo .slide_wrap {
      border-left: 5px solid #fff;
      border-right: 5px solid #fff; }
    .slider_jumbo .slick-prev,
    .slider_jumbo .slick-next {
      width: 16%;
      max-width: 315px; } }

.slider_reviews .slick-prev,
.slider_reviews .slick-next {
  z-index: 999999;
  width: 44px;
  border-radius: 6px;
  background-color: transparent;
  background-color: #fff;
  background-size: 18px;
  opacity: 1; }
  .slider_reviews .slick-prev:hover,
  .slider_reviews .slick-next:hover {
    opacity: 1; }

.slider_reviews .slick-prev {
  background-image: url("/_/images/icons/slide_prev-text.png"); }

.slider_reviews .slick-next {
  background-image: url("/_/images/icons/slide_next-text.png"); }

.slider_reviews .slick-prev:hover {
  background-image: url("/_/images/icons/slide_prev-review.png"); }

.slider_reviews .slick-next:hover {
  background-image: url("/_/images/icons/slide_next-review.png"); }

@media (min-width: 992px) {
  .slider_reviews {
    /*		.slick-prev,
		.slick-next {
			width: 44px;
			background-size: 100%;
		}
*/ } }

.video_wrapper {
  margin: 0 0 20px;
  padding: 4px;
  background-color: #fff;
  border: 1px solid #ddd; }

.about_video {
  border: 4px solid #060301; }
  @media (min-width: 768px) {
    .about_video {
      float: right;
      width: 65%;
      margin: 0 10px 15px 15px; } }

.youtube-player {
  position: relative;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background: #000; }
  .youtube-player.yt-16x9 {
    padding-bottom: 56.23%; }
  .youtube-player.yt-4x3 {
    padding-bottom: 75%; }

.youtube-player iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: transparent; }

.youtube-player img {
  display: block;
  position: absolute;
  z-index: 2000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  height: auto;
  margin: auto;
  border: none;
  cursor: pointer;
  opacity: 0.7;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms; }
  .youtube-player img:hover {
    opacity: 1.0; }

.youtube-player .play {
  position: absolute;
  z-index: 5000;
  height: 72px;
  width: 72px;
  left: 50%;
  top: 50%;
  margin-left: -36px;
  margin-top: -36px;
  background: url("/_/images/icons/play.png") no-repeat;
  cursor: pointer; }
  .youtube-player .play:hover + img {
    opacity: 1.0; }

.widget {
  margin: 0 0 1.5em;
  padding: 20px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 25px 20px -25px rgba(0, 0, 0, 0.3); }

.widget--plain {
  background-color: transparent;
  box-shadow: none; }

.widget_list ul > li {
  margin: 0;
  padding: 5px 8px; }
  .widget_list ul > li:not(:last-child) {
    border-bottom: 1px dotted #ccc; }
  .widget_list ul > li:nth-child(even) {
    background-color: #f8f8f8; }
  .widget_list ul > li p {
    margin: 0 0 5px;
    font-size: 0.9rem;
    font-style: italic;
    line-height: 1.1em; }

.widget-title {
  position: relative;
  margin: -20px -20px 15px;
  padding: 30px 20px 15px 20px;
  font-size: 1.25rem;
  font-weight: 600;
  font-family: "Montserrat", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1em;
  border-radius: 5px 5px 0 0;
  background-color: #ccc; }
  .widget--primary .widget-title {
    background-color: #41A72C;
    color: #fff; }
  .widget--secondary .widget-title {
    background-color: #060301;
    color: #fff; }
  .widget--highlight .widget-title {
    background-color: #01A3D9;
    color: #fff; }
  .widget--accent .widget-title {
    background-color: #F1D620;
    color: #fff; }
  .widget--success .widget-title {
    background-color: #28a745;
    color: #fff; }
  .widget--danger .widget-title {
    background-color: #dc3545;
    color: #fff; }
  .widget--warning .widget-title {
    background-color: #ffc107;
    color: #fff; }
  .widget--info .widget-title {
    background-color: #6610f2;
    color: #fff; }
  .widget--text .widget-title {
    background-color: #343a40;
    color: #fff; }
  .widget-title.title-plain {
    margin: 0 0 15px;
    padding: 0;
    background-color: transparent; }

.widget--primary .title-plain {
  color: #41A72C; }

.widget--secondary .title-plain {
  color: #060301; }

.widget-subtitle {
  margin: 15px -24px;
  padding: 0.5em 24px;
  font-size: 1.1rem;
  font-weight: 600;
  background-color: #e5e5e5; }

.widget-note {
  font-size: 0.925rem;
  font-style: italic;
  line-height: 1.25em; }

.foot-note {
  font-weight: 800;
  color: #dc3545; }

.widget_estimate p {
  font-size: 0.92rem;
  line-height: 1.3em; }

.widget_estimate .button_conversion {
  padding-left: 50px;
  font-size: 1.1rem;
  background-size: 45px auto;
  box-shadow: none;
  transform: scale(0.875);
  transition-duration: 150ms, 150ms, 100ms; }
  .widget_estimate .button_conversion:hover, .widget_estimate .button_conversion:focus {
    padding-left: 15px;
    padding-right: 50px;
    transform: scale(1);
    transition-duration: 300ms, 150ms, 50ms, 150ms;
    transition-delay: 100ms, 50ms, 0ms, 150ms; }

@media (min-width: 768px) and (max-width: 991px) {
  .widget_estimate .widget-text {
    display: flex;
    flex-wrap: wrap;
    align-items: center; }
    .widget_estimate .widget-text p {
      width: 50%;
      margin: 0;
      text-align: left; } }

.widget_feedback .widget-title {
  background-color: #fd7e14;
  color: #fff; }

.widget_pricing b {
  float: right;
  color: #329d4b; }
  .widget_pricing b del {
    font-weight: 400;
    color: #aaa; }

.widget-quick_form .form {
  margin: 0 0 1.5em;
  padding: 20px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 25px 20px -25px rgba(0, 0, 0, 0.3); }

.widget-quick_form .form-title {
  position: relative;
  margin: -20px -20px 15px;
  padding: 30px 20px 15px 20px;
  font-size: 1.25rem;
  font-weight: 600;
  font-family: "Montserrat", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1em;
  border-radius: 5px 5px 0 0;
  background-color: #060301;
  color: #fff; }

.widget_review {
  border: 3px solid #fd7e14;
  box-shadow: none; }
  .widget_review .review cite {
    vertical-align: middle; }
    .widget_review .review cite img {
      display: block;
      margin: 0 auto 10px;
      vertical-align: middle; }
  @media (min-width: 768px) and (max-width: 991px) {
    .widget_review .review cite img {
      display: inline-block;
      margin: 0 7px 0 0; } }

.widget_scheduling {
  padding: 25px 0; }
  .widget_scheduling .widget-title {
    margin: -25px 0 15px;
    padding: 35px 50px 15px 15px; }
  .widget_scheduling p {
    padding: 0 15px; }
  .widget_scheduling ul > li {
    padding: 15px;
    line-height: 1em;
    border-bottom: none !important; }
    .widget_scheduling ul > li:nth-child(even) {
      border-top: 1px dotted #ccc;
      border-bottom: 1px dotted #ccc !important;
      background-color: #eaeaea; }
    .widget_scheduling ul > li strong {
      font-weight: 900;
      line-height: 1.5em; }
  .widget_scheduling .scheduling_phone {
    font-size: 1.5rem;
    font-weight: 900;
    line-height: 1em;
    color: #017da6; }
  .widget_scheduling .img-mindbody {
    max-width: 215px; }
  .widget_scheduling .widget-note {
    margin: 1em 0 0;
    padding: 1em 15px 0;
    border-top: 1px solid #ccc; }
    .widget_scheduling .widget-note .foot-note {
      font-style: normal; }
  @media (min-width: 768px) and (max-width: 991px) {
    .widget_scheduling ul {
      display: flex;
      text-align: center; }
      .widget_scheduling ul li {
        width: 33.33333%; }
      .widget_scheduling ul li:nth-child(2) {
        border: 1px dotted #ccc !important; } }

.block {
  padding: 45px 0; }
  @media (min-width: 992px) {
    .block .row {
      justify-content: center; }
    .block .block_content {
      flex: 0 0 83.33333%;
      max-width: 83.33333%; } }
  @media (min-width: 1200px) {
    .block .block_content {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; } }

.block_main {
  padding: 30px 0 0; }
  .block_main + .block_main {
    padding: 0 0 30px; }

.block_title,
.block_more {
  line-height: 1em;
  text-align: center;
  background-size: 1px 3px;
  background-repeat: repeat-x;
  background-position: center center; }
  .block_title small,
  .block_more small {
    line-height: 1em; }

.block_title {
  margin-bottom: 15px;
  background-image: url("/_/images/layout/line-primary.png"); }

.block-title,
.block_more p {
  display: inline-block;
  margin: 0;
  padding: 10px 20px;
  line-height: 1em;
  background-color: #ececec; }
  .bg--white .block-title, .bg--white
  .block_more p {
    background-color: #fff; }

.block-title {
  font-weight: 700;
  text-transform: uppercase; }

.block-subtitle {
  margin-top: 0;
  font-family: "Montserrat", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 900;
  text-transform: uppercase; }

.block_more {
  background-size: 1px 1px;
  background-image: url("/_/images/layout/line-secondary.png"); }

.content_imgs img {
  margin-bottom: 10px; }

@media (max-width: 991px) {
  .content_imgs.imgs-multi img {
    float: left;
    width: 49%; }
    .content_imgs.imgs-multi img:nth-child(even) {
      float: right; } }

@media (min-width: 768px) and (max-width: 991px) {
  .content_imgs.imgs-single img {
    width: 49%; }
  .content_imgs.imgs-single.content_left img {
    float: right; }
  .content_imgs.imgs-single.content_right img {
    float: left; } }

.front_about .about_wrapper > div {
  width: 50%; }
  .front_about .about_wrapper > div:first-child {
    padding-right: 8px; }
  .front_about .about_wrapper > div:last-child {
    padding-left: 8px; }

@media (min-width: 768px) {
  .front_about .about_title {
    margin-bottom: 30px; }
  .front_about .about_wrapper {
    max-width: 600px;
    margin: -105px auto 0;
    padding: 2em 2em 0;
    border-top: 1px solid #bcbec0;
    box-shadow: 0 -22px 10px -20px rgba(0, 0, 0, 0.3);
    background-image: url("/_/images/layout/corner-tl.png"), url("/_/images/layout/corner-tr.png");
    background-size: 180px 180px, 180px 180px;
    background-position: top left, top right;
    background-repeat: no-repeat, no-repeat; } }

@media (min-width: 992px) {
  .front_about .about_wrapper {
    max-width: 720px; } }

.map_wrapper {
  background-image: linear-gradient(to right, #AADAFF 50%, #B9E8A7 50%); }

.about-map {
  padding: 250px 0 0; }
  .about-map.constrain {
    max-width: 1170px;
    margin: 0 auto; }
  @media (min-width: 576px) {
    .about-map {
      padding: 380px 0 0; } }
  @media (min-width: 992px) {
    .about-map {
      padding: 420px 0 0; } }

.about_wrapper {
  background-color: #fff;
  padding: 2em 0 0;
  display: flex;
  justify-content: center; }
  .about_wrapper .hours-title,
  .about_wrapper .hours-item {
    white-space: nowrap; }

.front_welcome {
  border-top: 5px solid #fff;
  border-bottom: 5px solid #fff; }
  @media (min-width: 992px) {
    .front_welcome .row {
      align-items: center; }
    .front_welcome .services_media {
      flex: 0 0 50%;
      max-width: 50%; }
    .front_welcome .services_content {
      flex: 0 0 50%;
      max-width: 50%; }
    .front_welcome .video_wrapper {
      margin-bottom: 0; } }

.front_welcome_more {
  padding: 15px 0;
  background-color: #343a40;
  color: #fff; }

.front_services {
  border-top: 5px solid #fff;
  border-bottom: 5px solid #ddd; }

.service_specials .service_special {
  box-sizing: border-box; }

@media (max-width: 991px) {
  .service_specials .service_special:not(:last-child) {
    margin-bottom: 1.75em; } }

@media (min-width: 768px) and (max-width: 991px) {
  .service_specials {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    .service_specials .service_special {
      width: 48.5%; }
      .service_specials .service_special:first-child {
        margin-right: 3%; }
      .service_specials .service_special:not(:last-child) {
        margin-bottom: 3%; }
      .service_specials .service_special:last-child {
        width: 65%; } }

@media (min-width: 992px) {
  .service_specials {
    display: flex;
    flex-wrap: wrap; }
    .service_specials .service_special {
      width: 32%;
      margin-bottom: 1em; }
    .service_specials .service_special:nth-child(2) {
      margin: 0 2% 1em; } }

.service_special {
  margin: 0 0 1.5em;
  padding: 0;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 25px 20px -25px rgba(0, 0, 0, 0.3); }
  .service_special .special-media {
    border-radius: 5px 5px 0 0; }
  .service_special .special_content {
    padding: 0 20px 20px; }
    .service_special .special_content > *:last-child {
      margin-bottom: 0; }
  .service_special h3 {
    font-size: 1.2rem;
    letter-spacing: -0.0425em; }
  .service_special p {
    font-size: 0.9rem;
    line-height: 1.475em; }

.services_more {
  margin-top: 1em; }
  .services_more small {
    display: block;
    margin-top: 10px; }

.front_jumbo {
  position: relative;
  padding: 0;
  border-top: 5px solid #fff;
  background-color: #fff; }

.jumbo_conversion {
  padding: 15px 0;
  text-align: center; }
  .jumbo_conversion .button_conversion {
    padding: 15px 20px 15px 55px;
    font-size: 1.125rem;
    background-size: 55px auto;
    transform: scale(0.925); }
    .jumbo_conversion .button_conversion:hover, .jumbo_conversion .button_conversion:focus {
      padding: 15px 55px 15px 20px;
      transform: scale(1); }
  @media (min-width: 768px) {
    .jumbo_conversion {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 113px;
      width: 100%;
      padding: 10px 0;
      font-size: 1.2rem; } }
  @media (min-width: 992px) {
    .jumbo_conversion {
      bottom: 118px;
      font-size: 1.375rem; }
      .jumbo_conversion .button_conversion {
        font-size: 1.3rem; } }
  @media (min-width: 1200px) {
    .jumbo_conversion {
      max-width: 1170px;
      margin: 0 auto; }
      .jumbo_conversion .button_conversion {
        font-size: 1.5rem; } }

.page_title {
  padding: 4em 0 2em;
  border-top: 5px solid #fff;
  border-bottom: 5px solid #fff;
  background: linear-gradient(-45deg, transparent 15%, rgba(255, 255, 255, 0.3) 50%, transparent 85%), linear-gradient(-150deg, rgba(0, 0, 0, 0.2) 20%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.2) 80%), #01A3D9;
  color: #fff; }
  .page_title .page-title {
    margin: 0;
    font-weight: 700;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.4); }
  @media (min-width: 768px) {
    .page_title {
      padding-top: 8em; } }
  @media (min-width: 992px) {
    .page_title {
      padding-top: 10em; } }

.front_reviews {
  padding: 1.5em 0;
  border-bottom: 5px solid #ddd;
  background-color: #fff; }
  .front_reviews .reviews-title,
  .front_reviews .reviews_more p {
    background-color: #fff; }
  @media (min-width: 992px) {
    .front_reviews {
      padding: 0;
      background-image: linear-gradient(to right, #fff 75%, #f8f9fa 75%); }
      .front_reviews .wrap {
        padding-top: 1.5em;
        padding-bottom: 1.5em; }
      .front_reviews .reviews_text {
        flex: 0 0 75%;
        max-width: 75%; }
      .front_reviews .reviews_content {
        flex: 0 0 100%;
        max-width: 100%; }
      .front_reviews .reviews_feedback {
        flex: 0 0 25%;
        max-width: 25%;
        margin-top: 0;
        background-color: #f8f9fa; }
        .front_reviews .reviews_feedback p {
          margin-top: 63px; } }
  @media (min-width: 1200px) {
    .front_reviews .reviews_feedback p {
      margin-top: 73px; } }

.reviews_title {
  margin-bottom: 0; }
  .reviews_title .reviews-title {
    padding: 0; }

.reviews_title,
.reviews_more,
.reviews_feedback {
  background-image: none; }

.reviews_content {
  padding-top: 15px;
  padding-bottom: 5px; }
  .reviews_content .review-wrap {
    margin: 0 64px;
    text-align: center; }
    .reviews_content .review-wrap cite {
      margin-top: 0.5em; }
  @media (max-width: 767px) {
    .reviews_content .review_stars {
      margin: 0 auto 10px; } }

.reviews_more p {
  padding: 0; }

.reviews_feedback {
  margin-top: 2em;
  text-align: center; }
  .reviews_feedback p {
    margin: 0;
    font-size: 1rem;
    line-height: 1em; }
  .reviews_feedback strong {
    display: block;
    margin: 0 0 5px;
    font-weight: 800; }
  @media (min-width: 768px) and (max-width: 991px) {
    .reviews_feedback strong {
      display: inline-block;
      margin: 0 15px 0 0; } }

.front_socials {
  padding: 15px 0 5px;
  text-align: center;
  background-color: #1d2124; }
  @media (min-width: 992px) {
    .front_socials {
      padding: 15px 0 12px; }
      .front_socials .socials_flex {
        display: flex;
        justify-content: center;
        align-items: center; }
      .front_socials .social_title {
        margin: 0;
        padding-right: 10px; } }

.social_title {
  margin-bottom: 10px;
  line-height: 1em;
  text-transform: uppercase; }

.social--title {
  display: inline-block;
  font-size: 1.2rem;
  font-weight: 800;
  line-height: 1em;
  letter-spacing: -0.0275em;
  color: #fff; }

.social-list {
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
  list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
  margin-bottom: 0;
  line-height: 1em; }
  .social-list li {
    display: inline-block; }
  .social-list li {
    line-height: 1em; }
  @media (min-width: 576px) {
    .social-list {
      margin-left: 0;
      padding-left: 0;
      list-style-type: none;
      list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); }
      .social-list li {
        display: inline-block; }
      .social-list li {
        margin-bottom: 0 !important; } }

.social--item {
  -webkit-transition: transform ease-in-out 300ms;
  -moz-transition: transform ease-in-out 300ms;
  -ms-transition: transform ease-in-out 300ms;
  -o-transition: transform ease-in-out 300ms;
  transition: transform ease-in-out 300ms;
  display: inline-block;
  padding: 0 10px;
  line-height: 0;
  background-size: 120px 31px;
  background-position: 10px 0;
  background-repeat: no-repeat;
  -webkit-transform: scale(0.95);
  -moz-transform: scale(0.95);
  -o-transform: scale(0.95);
  transform: scale(0.95); }
  .social--item:not(:last-child) {
    margin-right: 0.75em; }
  .social--item.social--google {
    background-image: url("/_/images/socials/google_hover.png"); }
  .social--item.social--facebook {
    background-image: url("/_/images/socials/facebook_hover.png"); }
  .social--item.social--yelp {
    background-image: url("/_/images/socials/yelp_hover.png"); }
  .social--item:hover {
    -webkit-transition: transform ease-in-out 300ms;
    -moz-transition: transform ease-in-out 300ms;
    -ms-transition: transform ease-in-out 300ms;
    -o-transition: transform ease-in-out 300ms;
    transition: transform ease-in-out 300ms;
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05); }
    .social--item:hover img {
      -webkit-transition: opacity ease-in-out 300ms;
      -moz-transition: opacity ease-in-out 300ms;
      -ms-transition: opacity ease-in-out 300ms;
      -o-transition: opacity ease-in-out 300ms;
      transition: opacity ease-in-out 300ms;
      opacity: 0; }
  .social--item img {
    -webkit-transition: opacity ease-in-out 300ms;
    -moz-transition: opacity ease-in-out 300ms;
    -ms-transition: opacity ease-in-out 300ms;
    -o-transition: opacity ease-in-out 300ms;
    transition: opacity ease-in-out 300ms;
    width: 120px;
    height: 31px;
    opacity: 1; }

.banner {
  padding: 0 0 20px;
  border-top: 10px solid #41A72C;
  background-color: #fff; }
  @media (min-width: 768px) {
    .banner {
      padding: 20px 0 0; } }

.banner_button {
  margin: 15px 0 0; }

.banner_branding .branding-logo {
  margin: -40px auto 20px; }

@media (min-width: 768px) {
  .banner_branding .branding-logo {
    margin: 0 auto; } }

.banner_contact {
  text-align: center; }
  .banner_contact.contact_long .contact-call {
    margin-bottom: 0; }
  @media (min-width: 576px) {
    .banner_contact.contact_local {
      text-align: right; }
      .banner_contact.contact_local .contact-call {
        margin-bottom: 0; }
    .banner_contact.contact_long {
      text-align: left; } }

@media (max-width: 991px) {
  .sidebar {
    padding-top: 2em; } }

.site_info {
  padding: 30px 0;
  text-align: center;
  border-top: 8px solid #ececec;
  box-shadow: 0 -5px 0 #000;
  background-color: #317f21;
  color: #fff; }
  .site_info .info_company {
    margin-bottom: 20px; }
  @media (min-width: 768px) {
    .site_info .info_company {
      margin-bottom: 0;
      text-align: left; }
    .site_info .info_copyright {
      text-align: right; } }
