.widget_pricing {

	b {
		float: right;
		color: theme(success, desat);
		del {
			font-weight: 400;
			color: #aaa;
		}
	}

}