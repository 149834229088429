.front_welcome {

	border-top: 5px solid #fff;
	border-bottom: 5px solid #fff;

	@include media-breakpoint-up(lg) {
		.row { align-items: center; }
		.services_media { @include make-col(6); }
		.services_content { @include make-col(6); }
		.video_wrapper { margin-bottom: 0; }
	}

	@include media-breakpoint-up(xl) {
	}

}

.front_welcome_more {
	padding: 15px 0;
	background-color: theme(text, base);
	color: $white;
}