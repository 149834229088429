.alert_scheduling {
	
/*	background: 	linear-gradient(to right, rgba(#B72A6F, 0.25), rgba(#FF8A00, 0.25) ),
					$white;*/

	.container { padding: 0 15px !important; }

	.alert {
		position: relative;
		padding-top: 0;
		padding-bottom: 0;
		background-color: $white !important;
		a { color: #b62a71 }
	}

	@include media-breakpoint-up(md) {
		//padding-top: 15px !important;
		background: 	linear-gradient(to top, theme(text, base) 68px, transparent 68px ),
						linear-gradient(to right, rgba(#B72A6F, 0.8), rgba(#FF8A00, 0.8) ),
						$white;
		
		.alert {
			padding-top: 0;
			background-color: transparent !important;
		}

		.container { padding-top: 15px !important; }

		.button_close { top: 15px !important; }

		.wrap {
			padding: 30px 30px 0;
			border-radius: 3px;
			background-color: $white;
		}

		.alert_note { margin: 15px 0 0; }

	}

	@include media-breakpoint-up(lg) {
		.wrap { @include make-col(9); }
		.mindbody-link { margin-bottom: 20px; }
	}

	@include media-breakpoint-up(xl) {
		.wrap { @include make-col(8); }
		.scheduling-title { font-size: 1.35rem; }
	}

}

	.alert_text {
		//max-width: 720px;
		margin-right: 35px;
		@include media-breakpoint-up(lg) {
			margin: 0 auto;
			padding-right: 35px;
		}
	}

	.scheduling-title {
		margin-right: 0;
		color: #e55828;
		font-weight: 700;
		text-transform: uppercase;
	}

	.mindbody-link {
		display: block;
		max-width: 240px;
		margin-bottom: 10px;
		font-weight: 600;
		//text-decoration: none;
		//color: theme(text, base) !important;
		small { display: block; }
		//&:hover { color: #b62a71 !important; }
		@include media-breakpoint-up(sm) {
			float: right;
			margin: 0 0 0 10px;
			text-align: right;
			small { margin-top: -5px; }
		}
	}

	.alert_note {
		padding: 15px;
		font-size: 0.96rem;
		font-weight: 600;
		line-height: 1.175em;
		background-color: theme(text, base);
		color: $white;
		@include media-breakpoint-only(sm) {
			p { max-width: 540px; margin: 0 auto; }
		}
		@include media-breakpoint-up(sm) {
			text-align: center;
		}
	}