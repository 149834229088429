.block {
	padding: 45px 0;

	@include media-breakpoint-up(lg) {
		.row { justify-content: center }
		.block_content { @include make-col(10); }
	}

	@include media-breakpoint-up(xl) {
		.block_content { @include make-col(8); }
	}

}



.block_main {
	padding: 30px 0 0;
	+ .block_main {
		padding: 0 0 30px;
	}
}


.block_title,
.block_more {
	line-height: 1em;
	text-align: center;
	background-size: 1px 3px;
	background-repeat: repeat-x;
	background-position: center center;
	small { line-height: 1em; }
}

.block_title {
	margin-bottom: 15px;
	background-image: url("/_/images/layout/line-primary.png");
}

	.block-title,
	.block_more p {
		display: inline-block;
		margin: 0;
		padding: 10px 20px;
		line-height: 1em;
		background-color: $body-bg;
		.bg--white & {
			background-color: $white;
		}
	}

	.block-title {
		@extend %h2;
		font-weight: 700;
		text-transform: uppercase;
	}

	.block-subtitle {
		margin-top: 0;
		font-family: $font-family-body;
		font-weight: 900;
		text-transform: uppercase;
	}

.block_more {
	background-size: 1px 1px;
	background-image: url("/_/images/layout/line-secondary.png");
}

	.content_imgs {
		img { margin-bottom: 10px; }
		&.imgs-multi {
			@include media-breakpoint-down(md) {
				img {
					float: left;
					width: 49%;
					&:nth-child(even) { float: right; }
				}
			}
		}
		&.imgs-single {
			@include media-breakpoint-only(md) {
				img { width: 49%; }
				&.content_left img { float: right; }
				&.content_right img { float: left; }
			}
		}
	}	