.widget_scheduling {

	padding: 25px 0;

	.widget-title {
		margin: -25px 0 15px;
		padding: 35px 50px 15px 15px;
	}

	p { padding: 0 15px; }

	ul > li {
		padding: 15px;
		line-height: 1em;
		border-bottom: none !important;

		&:nth-child(even) {
			border-top: 1px dotted #ccc;
			border-bottom: 1px dotted #ccc !important;
			background-color: #eaeaea;
		}

		strong {
			font-weight: 900;
			line-height: 1.5em;
		}

	}

	.scheduling_phone {
		font-size: 1.5rem;
		font-weight: 900;
		line-height: 1em;
		color: theme(highlight, dark);
	}

	.img-mindbody {
		max-width: 215px;
	}

	.widget-note {
		margin: 1em 0 0;
		padding: 1em 15px 0;
		border-top: 1px solid #ccc;
		.foot-note { font-style: normal; }
	}


	@include media-breakpoint-only(md) {
		ul {
			display: flex;
			text-align: center;
			li { width: percentage(1/3); }
			li:nth-child(2) { border: 1px dotted #ccc !important; }
		}
	}

}