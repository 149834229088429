[class*="btn-icon"] {
	padding: 15px 15px 15px 45px;
	font-size: 14px;
	font-weight: 600;
	line-height: 1em;
	text-transform: uppercase;
	background-position: 12px 9px;
	background-repeat: no-repeat;
	background-size: 24px;
}

.btn-icon--calendar {
	background-image: url("/_/images/icons/btn-calendar.png");
	&:hover { background-image: url("/_/images/icons/btn-calendar-hover.png"); }
}

.btn-icon--map {
	background-image: url("/_/images/icons/btn-map.png");
	&:hover { background-image: url("/_/images/icons/btn-map-hover.png"); }
}

.btn-icon--review {
	background-image: url("/_/images/icons/btn-review.png");
	&:hover { background-image: url("/_/images/icons/btn-review-hover.png"); }	
}