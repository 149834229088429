.widget_estimate {
	
	p {
		font-size: 0.92rem;
		line-height: 1.3em;
	}

	.button_conversion {

		padding-left: 50px;
		font-size: 1.1rem;
		background-size: 45px auto;
		box-shadow: none;
	    transform: scale(0.875);
		transition-duration: 150ms, 150ms, 100ms;

	    &:hover,
	    &:focus {
			padding-left: 15px;
    		padding-right: 50px;
	        transform: scale(1);
			transition-duration: 300ms, 150ms, 50ms, 150ms;
			transition-delay: 100ms, 50ms, 0ms, 150ms;
	    }  

	}

	@include media-breakpoint-only(md) {
		.widget-text {
			@include make-flex();
			align-items: center;
			p { width: 50%; margin: 0; text-align: left; }
		}
	}

}
