@mixin btn-reg($bg: $color-link, $color: $white, $lighten: 12.5%) {
	display: inline-block;
    padding: 12px 15px;
    font-family: $font-family-sans-serif;
    font-weight: 900;
    line-height: 1em;
    text-decoration: none;
    text-transform: uppercase;
	border-radius: 3px;
    border: 1px solid darken($bg, 10%);
    background-color: $bg;
    color: $color;
    @include transition(all ease-in-out 200ms);
	&:link, &:visited, &:active {
	    text-decoration: none;
	    border: 1px solid darken($bg, 10%);
	    background-color: $bg;
	    color: $color;
	    @include transition(all ease-in-out 200ms);
	}
    &:hover,
    &:focus {
        background-color: lighten($bg, $lighten);
        color: $color;
        @include transition(all ease-in-out 200ms);
    }    
}


[class*="btn"] {
	@include btn-reg;
    line-height: 1em;
	cursor: pointer;

	small { display: block; line-height: 1em }

	&:focus {
		outline: 4px solid rgba(0,0,0,0.25);
	}

}

	%btn_text, .btn-text { @include btn-reg($color-text) }
	%btn_link, .btn-link { @include btn-reg($color-link) }
	%btn_primary, .btn-primary { @include btn-reg($color-primary) }
	%btn_secondary, .btn-secondary { @include btn-reg(darken($color-secondary, 5%), $white, 12.5%) }
	%btn_highlight, .btn-highlight { @include btn-reg($color-highlight) }
	%btn_accent, .btn-accent { @include btn-reg($color-accent, $white, 7.5%) }
	%btn_success, .btn-success { @include btn-reg($color-success) }
	%btn_danger, .btn-danger { @include btn-reg($color-danger) }
	%btn_info, .btn-info { @include btn-reg($color-info) }
	%btn_review, .btn-review { @include btn-reg($color-review) }
	%btn_warning, .btn-warning { @include btn-reg($color-warning) }

	%btn-block, .btn-block { display: block; text-align: center; }

	.btn-icon {
		vertical-align: bottom;
		img {
			display: inline-block;
			width: 22px;
			margin-right: 0.75em;
			vertical-align: middle;
		}
		&.btn--lg img { width: 28px; }
		&.btn--sm img { width: 18px; }
	}

	%btn--sm, .btn--sm { padding: 8px 12px }
		@mixin btn--sm { padding: 8px 12px }

	%btn--md, .btn--md { padding: 12px 15px }
		@mixin btn--md { padding: 12px 15px }

	%btn--lg, .btn--lg { padding: 15px 20px }
		@mixin btn--lg { padding: 15px 20px	}

	%btn--wide, .btn--wide { display: block; width: 100% }
		@mixin btn--wide { display: block; width: 100% }