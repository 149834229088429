$form_focus_border: 			$yellow;
$form_focus_bg: 				lighten($yellow, 40%);
$placeholder-color: 		rgba($white, 0.9);
$placeholder-hover: 		rgba(black, 0.3);

.form {

	font-size: 1rem;
	font-family: $font-family-sans-serif;
	line-height: 1em;

}

	.form_group {
		margin: 0 0 0.75em;
		padding: 0;
		&.form_hp { display: none; }
		&.form_clear { clear: both; }
	}

		label {
			display: block;
			margin: 0 0 1px 2px;
			font-size: 0.9rem;
			font-weight: 600;
			line-height: 1em;
			letter-spacing: -0.0275em;
		}

		.form_instruct, .form_example {
			margin-left: 1px;
			line-height: 1.275em;
		}
			.form_instruct {
				font-size: 0.95rem;
				margin-top: 0.25em;
				margin-bottom: 0.25em;
			}
			.form_example {
				margin-top: 0.25em;
				font-size: 0.85rem;
			}

		.form_control {
			display: block;
			width: 100%;
			margin: 0 0 5px;
			padding: 9px 7px;
			font-size: 1.1rem;
			line-height: 1em;
			border-radius: 2px;
			border: 1px solid #ddd;
			background-color: $white;
			outline: none;
			&:focus {
				border-color: $form_focus_border;
				background-color: $form_focus_bg;
			}
		}

			textarea.form_control {
				min-height: 150px;
				line-height: 1.275em;
			}

	.form_error .form_control {
		border-radius: 2px 2px 0 0;
		border-color: $color-danger !important;
	}

		.error-message {
			margin: -5px 0 0;
			padding: 6px 4px 4px;
			font-size: 0.7em;
			font-weight: 800;
			line-height: 1em;
			text-transform: uppercase;
			background-color: theme(danger, base);
			color: $white;
		}


.form_section {
	margin-bottom: 30px;
	padding: 15px;
	border: 2px groove #eeeeee;

	legend {
		padding: 8px 15px;
		font-weight: 800;
	}

	label {
		margin-bottom: 5px;
	}

}
