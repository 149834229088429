.front_about {



	.about_wrapper > div {
		width: 50%;
		&:first-child { padding-right: 8px }
		&:last-child { padding-left: 8px }
	}

	@include media-breakpoint-up(md) {
		.about_title { margin-bottom: 30px; }
		.about_wrapper {
			max-width: 600px;
			margin: -105px auto 0;
			padding: 2em 2em 0;
			border-top: 1px solid #bcbec0;
			box-shadow: 0 -22px 10px -20px rgba(0,0,0,0.3);
			background-image: 	url("/_/images/layout/corner-tl.png"),
								url("/_/images/layout/corner-tr.png");
			background-size: 180px 180px, 180px 180px;
			background-position: top left, top right;
			background-repeat: no-repeat, no-repeat;
		}
	}

	@include media-breakpoint-up(lg) {
		.about_wrapper {
			max-width: 720px;
		}
	}

	@include media-breakpoint-up(xl) {}

}

	.map_wrapper {
		background-image: linear-gradient(
			to right,
			#AADAFF 50%,
			#B9E8A7 50%
		);
	}

	.about-map {
		padding: 250px 0 0;
		&.constrain {
			max-width: 1170px;
			margin: 0 auto;
		}
		@include media-breakpoint-up(sm) { padding: 380px 0 0; }
		@include media-breakpoint-up(lg) { padding: 420px 0 0; }
	}

	.about_wrapper {
		background-color: $white;
		padding: 2em 0 0;
		display: flex;
		justify-content: center;
		.hours-title,
		.hours-item {white-space: nowrap; }
	}
