.widget-quick_form {

	.form {
		margin: 0 0 1.5em;
		padding: 20px;
		border-radius: 5px;
		background-color: $white;
		box-shadow: 0 25px 20px -25px rgba(0, 0, 0, 0.3);		
	}

	.form-title {
		position: relative;
		margin: -20px -20px 15px;
		padding: 30px 20px 15px 20px;
		font-size: 1.25rem;
		font-weight: 600;
		font-family: $font-family-sans-serif;
		line-height: 1em;
		border-radius: 5px 5px 0 0;
		background-color: theme(secondary, base);	
		color: $white;	
	}

}