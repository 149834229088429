.branding-logo {
	display: block;
	overflow: visible;
	width: 100%;
	max-width: 240px;
	img {
		display: block;
		width: 100%;
		margin: 0;
	}
}
