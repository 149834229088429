.banner {
	padding: 0 0 20px;
	border-top: 10px solid theme(primary, base);
	background-color: $white;

	@include media-breakpoint-up(md) {
		padding: 20px 0 0;
	}

}


	// BANNER BUTTON
	.banner_button { margin: 15px 0 0; }


	// BANNER BRANDING
	.banner_branding {
		.branding-logo {
			margin: -40px auto 20px;
		}
		@include media-breakpoint-up(md) {
			.branding-logo { margin: 0 auto; }
		}
	}

	.banner_contact {
		text-align: center;
		&.contact_long .contact-call { margin-bottom: 0; }

		@include media-breakpoint-up(sm) {
			&.contact_local {
				text-align: right;
				.contact-call { margin-bottom: 0; }
			}
			&.contact_long { text-align: left; }
		}

	}
