.contact-call {
	line-height: 1em;
}

	.contact-title {
		display: block;
		font-size: 0.9rem;
		font-weight: $font-weight-bold;
	}

	.contact-phone {
		display: inline-block;
		font-size: 1.5rem;
		font-weight: $font-weight-bolder;
		line-height: 1em;
		letter-spacing: -0.04em;
		color: theme(text, dark);
	}

	.contact-vanity {
		display: block;
		font-size: 0.9rem;
	}