.widget {

	@include card(20px, 5px, $white);

}

	.widget--plain {
		background-color: transparent;
		box-shadow: none;
	}

	.widget_list {
		ul > li {
			margin: 0;
			padding: 5px 8px;

			&:not(:last-child) {
				border-bottom: 1px dotted #ccc;
			}

			&:nth-child(even) { background-color: #f8f8f8; }

			p {
				margin: 0 0 5px;
				font-size: 0.9rem;
				font-style: italic;
				line-height: 1.1em;
			}

		}
	}

.widget-title {
	position: relative;
	margin: -20px -20px 15px;
	padding: 30px 20px 15px 20px;
	font-size: 1.25rem;
	font-weight: 600;
	font-family: $font-family-sans-serif;
	line-height: 1em;
	border-radius: 5px 5px 0 0;
	background-color: #ccc;

	.widget--primary & { background-color: theme(primary, base); color: $white; }
	.widget--secondary & { background-color: theme(secondary, base); color: $white; }
	.widget--highlight & { background-color: theme(highlight, base); color: $white; }
	.widget--accent & { background-color: theme(accent, base); color: $white; }
	.widget--success & { background-color: theme(success, base); color: $white; }
	.widget--danger & { background-color: theme(danger, base); color: $white; }
	.widget--warning & { background-color: theme(warning, base); color: $white; }
	.widget--info & { background-color: theme(info, base); color: $white; }
	.widget--text & { background-color: theme(text, base); color: $white; }

	&.title-plain {
		margin: 0 0 15px;
		padding: 0;
		background-color: transparent;
	}

}

	.widget--primary .title-plain { color: theme(primary, base); }
	.widget--secondary .title-plain { color: theme(secondary, base); }

.widget-subtitle {
	margin: 15px -24px;
	padding: 0.5em 24px;
	font-size: 1.1rem;
	font-weight: 600;
	background-color: #e5e5e5;
}

.widget-note {
	font-size: 0.925rem;
	font-style: italic;
	line-height: 1.25em;

}

	.foot-note {
		font-weight: 800;
		color: theme(danger, base);
	}
