	.button_close {
		//float: right;
		//margin: 0 0 0 15px;
		padding: 5px;
		width: 24px;
		height: 24px;

		font-size: 24px;
		line-height: 1em;
		text-align: center;
		
		border-radius: 3px;
		border: 1px solid theme(danger, dark);
		background-color: theme(danger, base);
		background-image: url("/_/images/icons/close.png");
		background-size: 14px 14px;
		background-position: center center;
		background-repeat: no-repeat;

		color: #fff;
		//opacity: 0.8;
		cursor: pointer;
		
		&:hover {
			border-color: theme(danger, base);
			background-color: theme(danger, light);
		}
	}