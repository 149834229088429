.banner-hours {
	text-decoration: none;
	color: #999;
	&:link, &:active, &:visited, &:focus {
		color: #999;
	}

	&:hover { color: #999; }

	b { font-weight: 400 }
}

.list-hours {

	.hours-title {
		padding: 0 0.5em 0.5em;
		font-size: 1.1rem;
		font-weight: 800;
		line-height: 1em;
		border-bottom: 2px solid theme(text, base);
	}

	.hours-item {
		padding: 0.25em 0.5em;
		font-size: 0.95rem;
		&:hover {
			background-color: rgba($color-text, 0.1);
			cursor: default;
		}
		&.active {
			font-weight: 600;
			background-color: rgba($color-secondary, 0.2);
		}
	}

	li:not(:last-child, .hours-title) {
		border-bottom: 1px solid #ccc;
	}

		b { display: inline-block; width: 60px; }

}
