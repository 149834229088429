
.service_menu {

	.menu {}

	.menu_item:not(:last-child) {
		border-bottom: 1px solid rgba(black, 0.2);
	}

	.menu_item.active {
		.menu_link {
			background-color: transparent;
			color: theme(highlight, base);
			font-size: 16px;
			font-weight: $font-weight-bolder;
		}
	}

	.menu_link {
		padding: 10px;
		font-size: 14px;
		font-weight: $font-weight-bold;
		text-transform: uppercase;
		color: $gray-700;
		&:hover {
			background-color: theme(primary, base);
			color: $white;
		}
	}

}