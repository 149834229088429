.slider_jumbo {

    margin: 0 auto;
    line-height: 0px;

    &:hover .slick-next { opacity: 1 }

    .slick-prev,
    .slick-next {
        z-index: 999999;
        top: auto;
        height: 106px;
        width: 44px;
        background-color: rgba(0,0,0,0.1);
        background-size: 18px;
        opacity: 1;
        &:hover {
            opacity: 1;
            background-color: rgba(0,0,0,0.3);
        }
    }

    .slick-prev {
        background-image: url("/_/images/icons/slide_prev-text.png");
    }
    .slick-next {
        background-image: url("/_/images/icons/slide_next-text.png");
    }

    .slide_wrap {
        display: block;
        width: 100%;
        max-width: 1170px;
        max-height: 580px;
        margin: 0 auto;
        text-align: center;
        background-position: center center;
        background-size: cover;
    }

    .slide_caption {
        text-align: center;
        border-top: 5px solid $white;
        background-color: theme(primary, dark);

        .caption-title {
            margin-bottom: 0.25em;
            font-size: 1.5rem;
        }
        .caption-text {
            font-size: 1.15rem;
            font-weight: $font-weight-bold;
            line-height: 1em;
            em {
                display: block;
                margin-top: 0.175em;
                font-size: 85%;
                font-weight: $font-weight-normal;
                line-height: 1em;
            }
        }
    }


    @include media-breakpoint-only(xs) {
        .slide_wrap,
        .slide,
        .slide img { height: 216px; }
    }


    @include media-breakpoint-up(md) {

        .slide_01 .slide_wrap { background-image: url("/_/images/slides/jumbo/md/01.jpg") }
        .slide_02 .slide_wrap { background-image: url("/_/images/slides/jumbo/md/02.jpg") }
        .slide_03 .slide_wrap { background-image: url("/_/images/slides/jumbo/md/03.jpg") }
        .slide_04 .slide_wrap { background-image: url("/_/images/slides/jumbo/md/04.jpg") }
        .slide_05 .slide_wrap { background-image: url("/_/images/slides/jumbo/md/05.jpg") }

            .slide_wrap { height: 390px; }

                .slide-placeholder { visibility: hidden; }

            .slide_caption {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                .caption-title {
                    margin: 0 15px 0 0;
                    text-align: right;
                }
                .caption-text {
                    margin: 0;
                    padding: 10px 0 10px 15px;
                    text-align: left;
                    border-left: 1px solid $white;
                }
            }

        .slick-prev,
        .slick-next { height: 98px; width: 60px; }

    }



    @include media-breakpoint-up(lg) {

        .slide_01 .slide_wrap { background-image: url("/_/images/slides/jumbo/lg/01.jpg") }
        .slide_02 .slide_wrap { background-image: url("/_/images/slides/jumbo/lg/02.jpg") }
        .slide_03 .slide_wrap { background-image: url("/_/images/slides/jumbo/lg/03.jpg") }
        .slide_04 .slide_wrap { background-image: url("/_/images/slides/jumbo/lg/04.jpg") }
        .slide_05 .slide_wrap { background-image: url("/_/images/slides/jumbo/lg/05.jpg") }

            .slide_wrap { height: 480px }

            .slide_caption {
                .caption-title {
                    font-size: 1.7rem;
                }
                .caption-text {
                    font-size: 1.3rem;
                }
            }

        .slick-prev,
        .slick-next { height: 103px; }

    }



    @include media-breakpoint-up(xl) {

        .slide {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
        }

        .slide_01 { background-image: url("/_/images/slides/jumbo/bg/01.jpg") }
        .slide_02 { background-image: url("/_/images/slides/jumbo/bg/02.jpg") }
        .slide_03 { background-image: url("/_/images/slides/jumbo/bg/03.jpg") }
        .slide_04 { background-image: url("/_/images/slides/jumbo/bg/04.jpg") }
        .slide_05 { background-image: url("/_/images/slides/jumbo/bg/05.jpg") }

            //.slide_wrap { height: 540px; }

            .slide_wrap {
                border-left: 5px solid $white;
                border-right: 5px solid $white;
            }

        .slick-prev,
        .slick-next {
            width: 16%;
            max-width: 315px;
        }

    }



}