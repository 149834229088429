.front_services {
	border-top: 5px solid #fff;
	border-bottom: 5px solid #ddd;
}

	.service_specials {
		
		.service_special { box-sizing: border-box; }

		@include media-breakpoint-down(md) {
			.service_special:not(:last-child) { margin-bottom: 1.75em; }
		}

		@include media-breakpoint-only(md) {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			.service_special {
				width: (97 / 2) * 1%;
				&:first-child { margin-right: 3%; }
				&:not(:last-child) { margin-bottom: 3%; }
				&:last-child { width: 65%; }
			}	
		}

		@include media-breakpoint-up(lg) {
			display: flex;
			flex-wrap: wrap;
			.service_special {
				width: (96 / 3) * 1%;
				margin-bottom: 1em;
			}
			.service_special:nth-child(2) {
				margin: 0 2% 1em;
			}
		}
	}

		.service_special {
			@include card(0, 5px, $white);

			.special-media {
				border-radius: 5px 5px 0 0;
			}

			.special_content {
				padding: 0 20px 20px;
				>*:last-child { margin-bottom: 0; }
			}

				h3 {
					font-size: 1.2rem; 
					letter-spacing: -0.0425em;
				}

				p {
					font-size: 0.9rem;
					line-height: 1.475em;
				}

		}

	.services_more {
		margin-top: 1em;
		small {
			display: block;
			margin-top: 10px;
		}
	}