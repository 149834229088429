
.faq_jump {

	margin-bottom: 2em;

	li { line-height: 1em !important }

	li:nth-child(even) { background-color: $white; }

	> li:not(:last-child) {
		margin-bottom: 0 !important;
		border-bottom: 1px solid #ddd;
	}

	a {
		display: inline-block;
		padding: 0.75em;
		font-size: 0.95rem;
		line-height: 1em;
		text-decoration: none;
		color: theme(text, base);
		&:hover {
			//text-decoration: underline;
			font-weight: 700;
			//background-color: $white;
			color: theme(primary, base);
		}
	}

}

.faq_group {

	.faq_item:nth-child(even) {
		background-color: rgba(255,255,255,1);
	}

}

	.faq_item {

		padding: 2em;
		border-bottom: 1px solid #ddd;
		text-align: right;

		h2, p { text-align: left }

		.faq--title {
			margin: 0 0 0.5em;
			font-size: 1.2rem;
			letter-spacing: 0;
		}

	}